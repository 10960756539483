import { useMemo, useState } from "react";
import { ModalType } from "../../pages/channels";
import { PlatformGridFragment } from "../../hasura.graphql";
import { AvailabilityRegionsType } from "../channels/channel-edit-form/types";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ErrorBoundary from "../error-boundary";
import PageTable from "../../common/page-table";
import Modal from "../modal";
import PlatformViewForm from "./platform-view-form";
import moment from "moment";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import TableRowsFilter from "../../common/filters/table-rows-filter";

const PlatformList: React.FC<{
  platforms: Array<PlatformGridFragment>;
  toggleView: string;
  setToggleView: React.Dispatch<React.SetStateAction<string>>;
  platformSearch: string;
  setPlatformSearch: React.Dispatch<React.SetStateAction<string>>;
  header?: string;
  url?: string;
}> = ({
  platforms,
  toggleView,
  setToggleView,
  platformSearch,
  setPlatformSearch,
  header,
  url,
}) => {
  const [platformId, setPlatformId] = useState<ModalType | null>(null);

  const filteredPlatforms = platforms.map((plat) => {
    return {
      id: plat.id,
      name: plat.name,
      customer_id: plat.account?.amagi_id,
      account_name: plat.account?.account_name,
      customer_status: plat.account?.status,
      platform_logo: plat.logo,
      customer_logo: plat.account?.logo,
      tier: plat.platform_tier || "--",
      regions: plat.availablity_country_and_regions
        ? Array.from(
            new Set(
              plat.availablity_country_and_regions.map(
                (g: AvailabilityRegionsType) => g.region
              )
            )
          )
        : [],
      platform_brand : plat.platform_brand 
      ? plat.platform_brand 
      : '--',
      platform_manager: plat.platform_manager
        ? plat.platform_manager.name
        : "-",
      available_in_amagi_now: plat.available_in_amagi_now || "--",
      ssai_by: plat.ssai_by || "--",
      inventory_model: plat.inventory_model || "--",
      vod_support: plat.vod_support || "--",
      stream_type: plat.stream_type.length > 0 ? plat.stream_type : [],
      epg: plat.epg || "--",
      epg_format: plat.epg_format || "--",
      overall_bitrate: plat.overall_bitrate || "--",
      subtitle_cc_format: plat.subtitle_cc_format || "--",
      ad_trigger_type: plat.ad_trigger_type || "--",
      ad_break: plat.ad_break || "--",
      platform_specific_encoding: plat.platform_specific_encoding || "--",
    };
  });

  const columns: any = useMemo(
    () => [
      {
        Header: "PLATFORM ID",
        accessor: "id",
        show: false,
      },
      {
        Header: "PLATFORM NAME",
        accessor: "name",
        Cell: ({ cell }: any) => (
          <div className="flex">
            <div className="w-16 h-12">
              {cell.row.original.platform_logo ? (
                <LazyLoadImage
                  className="h-full w-full border rounded-lg object-contain bg-lightPrimary"
                  src={
                    cell.row.original.platform_logo
                      ? `/${cell.row.original.platform_logo}`
                      : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${cell.row.original.name}`
                  }
                  alt={cell.row.original.platform_logo}
                />
              ) : (
                <div className="w-full h-full rounded-lg flex font-semibold text-xl text-gray-300 items-center justify-center object-contain border-b bg-gradient-to-r from-gradientBlue to-gradientOrange">
                  {cell.row.original.name[0]}
                </div>
              )}
            </div>
            <div className="ml-3 flex flex-col">
              <div className="flex">
                <span
                  className="cursor-pointer mr-2 hover:text-blue-500 hover:underline"
                  onClick={() => {
                    setPlatformId({
                      id: cell.row.original.id,
                      name: cell.row.original.name,
                    });
                  }}
                >
                  {cell.row.values.name.length > 30
                    ? `${cell.row.values.name.slice(0, 30)}...`
                    : cell.row.values.name}
                </span>
                <a
                  href={`${url}/${cell.row.original.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ExternalLinkIcon className="h-5 w-5 text-blue-500" />
                </a>
              </div>
              <span className="text-sm text-gray-500">
                {cell.row.original.id}
              </span>
            </div>
          </div>
        ),
        show: true,
      },
      {
        Header: "PLATFORM BRAND",
        accessor: "platform_brand",
        Filter: TableRowsFilter,
        filter: "multiSelect",
        show: true,
      },
      {
        Header: "CUSTOMER ID",
        accessor: "customer_id",
        show: false,
      },
      {
        Header: "CUSTOMER NAME",
        accessor: "account_name",
        Cell: ({ cell }: any) => (
          <div className="flex">
            {cell.row.original.customer_id ? (
              <>
                {cell.row.original.account_name && (
                  <>
                    <div className="w-16 h-12">
                      {cell.row.original.customer_logo ? (
                        <LazyLoadImage
                          className="h-full w-full border rounded-lg object-contain bg-lightPrimary"
                           src={
                            cell.row.original.logo && cell.row.original.logo.startsWith("uploads/")
                              ? `/${cell.row.original.logo}`
                              : cell.row.original.logo
                              ? `/logos/${cell.row.original.logo}`
                              : `https://via.placeholder.com/300x200.png/C1CFFB/1924C1?text=${cell.row.original.account_name}`
                          }
                          alt={cell.row.original.customer_logo}
                        />
                      ) : (
                        <div className="w-full h-full rounded-lg flex font-semibold text-xl text-gray-300 items-center justify-center object-contain border-b bg-gradient-to-r from-gradientBlue to-gradientOrange">
                          {cell.row.original.account_name[0]}
                        </div>
                      )}
                    </div>
                  </>
                )}
                <div className="ml-3 flex flex-col">
                  <a
                    href={`/customers/${cell.row.original.customer_id}/details`}
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-blue-500 hover:underline"
                  >
                    {cell.row.values.account_name}
                  </a>
                  <span className="text-sm text-gray-500">
                    {cell.row.original.customer_id}
                  </span>
                </div>
              </>
            ) : (
              <span>--</span>
            )}
          </div>
        ),
        Filter: TableRowsFilter,
        filter: "multiSelect",
        show: true,
      },
      {
        Header: "TIER",
        accessor: "tier",
        Filter: TableRowsFilter,
        filter: "multiSelect",
        show: true,
      },
      {
        Header: "REGIONS",
        accessor: "regions",
        Cell: ({ cell }: any) => (
          <div
            className="p-2 flex overflow-hidden pt-2"
            style={{ fontSize: "12px" }}
          >
            {cell.row.original.regions.map((g: string, index: number) => {
              if (index < 3) {
                return (
                  <span
                    key={index}
                    className="border border-gray-300 px-2 mr-2 rounded-full"
                  >
                    {g}
                  </span>
                );
              } else if (index === 3) {
                return (
                  <span
                    key={index}
                    className="border border-gray-300 px-2 mr-2 rounded-full"
                  >{`+ ${
                    cell.row.original.regions.length - (index + 1)
                  }`}</span>
                );
              } else return null;
            })}
          </div>
        ),
        Filter: TableRowsFilter,
        filter: "multiSelect",
        show: true,
      },
      {
        Header: "PLATFORM MANAGER",
        accessor: "platform_manager",
        Filter: TableRowsFilter,
        filter: "multiSelect",
        show: true,
      },
      {
        Header: "AVAILABLE IN AMAGI NOW",
        accessor: "available_in_amagi_now",
        Filter: TableRowsFilter,
        filter: "multiSelect",
        show: true,
      },
      {
        Header: "SSAI BY",
        accessor: "ssai_by",
        Filter: TableRowsFilter,
        filter: "multiSelect",
        show: true,
      },
      {
        Header: "INVENTORY MODEL",
        accessor: "inventory_model",
        Filter: TableRowsFilter,
        filter: "multiSelect",
        show: true,
      },
      {
        Header: "VOD SUPPORT",
        accessor: "vod_support",
        Filter: TableRowsFilter,
        filter: "multiSelect",
        show: true,
      },
      {
        Header: "STREAM TYPE",
        accessor: "stream_type",
        Filter: TableRowsFilter,
        filter: "multiSelect",
        show: true,
      },

      {
        Header: "EPG",
        accessor: "epg",
        Filter: TableRowsFilter,
        filter: "multiSelect",
        show: true,
      },
      {
        Header: "EPG FORMAT",
        accessor: "epg_format",
        Filter: TableRowsFilter,
        filter: "multiSelect",
        show: true,
      },
      {
        Header: "OVERALL BITRATE",
        accessor: "overall_bitrate",
        Filter: TableRowsFilter,
        filter: "multiSelect",
        show: true,
      },
      {
        Header: "SUBTITLE / CC FORMAT",
        accessor: "subtitle_cc_format",
        Filter: TableRowsFilter,
        filter: "multiSelect",
        show: true,
      },
      {
        Header: "AD TRIGGER TYPE",
        accessor: "ad_trigger_type",
        Filter: TableRowsFilter,
        filter: "multiSelect",
        show: true,
      },
      {
        Header: "AD BREAK",
        accessor: "ad_break",
        Filter: TableRowsFilter,
        filter: "multiSelect",
        show: true,
      },
      {
        Header: "PLATFORM SPECIFIC ENCODING",
        accessor: "platform_specific_encoding",
        Filter: TableRowsFilter,
        filter: "multiSelect",
        show: true,
      },
    ],
    [url]
  );

  const hideColumns = [
    "platform_manager",
    "available_in_amagi_now",
    "ssai_by",
    "inventory_model",
    "vod_support",
    "stream_type",
    "epg",
    "epg_format",
    "overall_bitrate",
    "subtitle_cc_format",
    "ad_trigger_type",
    "ad_break",
    "platform_specific_encoding",
  ];

  return (
    <>
      <ErrorBoundary>
        <PageTable
          fromDate=""
          toDate=""
          columns={columns}
          header={header}
          toggleView={toggleView}
          setToggleView={setToggleView}
          search={platformSearch}
          setSearch={setPlatformSearch}
          isExternal={true}
          height={window.innerHeight - 245}
          rowData={filteredPlatforms}
          hideColumns={hideColumns}
          warning="Platforms"
          exportHeaders={columns.map((col: any) => ({
            label: col.Header,
            key: col.accessor,
          }))}
          placeholder="Search by platform, customer, tier and region"
          fileName={`CDP_Platforms_${moment().format("YYYYMMDDHHmmss")}.csv`} 
          setFromDate={null}
          setToDate={null}
                />
        {platformId && (
          <Modal
            title={`All ${header}`}
            modalWidth="100vw"
            modalShow={platformId}
            onClose={setPlatformId}
          >
            <PlatformViewForm
              platformId={platformId.id}
              isModal={true}
              header={`All ${header}`}
              url="/platforms/grouped-platform"
            />
          </Modal>
        )}
      </ErrorBoundary>
    </>
  );
};

export default PlatformList;

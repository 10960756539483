import React, { useState, useEffect } from "react";
import DateRangePicker from "rsuite/esm/DateRangePicker";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface DateRangePickerCompProps {
  fromDate?: string | null;
  toDate?: string | null;
  setFromDate?: React.Dispatch<React.SetStateAction<string>> | null;
  setToDate?: React.Dispatch<React.SetStateAction<string>> | null;
}

const DateRangePickerComp: React.FC<DateRangePickerCompProps> = ({
  fromDate,
  toDate,
  setFromDate,
  setToDate,
}) => {
  const [selectionOrder, setSelectionOrder] = useState<"from" | "to">("from"); // eslint-disable-next-line
  const [isValidRange, setIsValidRange] = useState(true);

  useEffect(() => {
    setSelectionOrder("from");
  }, [fromDate, toDate]);

  const handleApplyDateRange = (startDate: Date, endDate: Date) => {
    const sixMonthsAfter = computeSixMonthsAfterDate(new Date(startDate));
    const today = new Date().toISOString().slice(0, 10);
    const endDateStr = endDate.toISOString().slice(0, 10);
    const isValid = sixMonthsAfter >= endDate;

    if (isValid) {
      if (endDateStr <= today) {
        setFromDate && setFromDate(startDate.toISOString());
        setToDate && setToDate(endDate.toISOString());
      } else {
        setFromDate && setFromDate(startDate.toISOString());
        setToDate && setToDate(today);
      }
      setIsValidRange(true);
    } else {
      setIsValidRange(false);
      toast.error("Please enter a date range within 6 months.");
    }
  };

  const computeSixMonthsAfterDate = (date: Date) => {
    const sixMonthsAfter = new Date(date);
    sixMonthsAfter.setMonth(sixMonthsAfter.getMonth() + 6);
    const today = new Date();

    return sixMonthsAfter < today ? sixMonthsAfter : today;
  };

  const shouldDisableDate = (date: Date) => {
    const today = new Date();
    const sixMonthsAhead = computeSixMonthsAfterDate(today);

    if (selectionOrder === "from") {
      return date > sixMonthsAhead || date > today;
    } else {
      const sixMonthsAfter = computeSixMonthsAfterDate(new Date(fromDate || today.toISOString()));
      return date > sixMonthsAfter || date >= today; 
    }
  };

  const handleSpecialRange = (range: string) => {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const lastSevenDays = new Date();
    lastSevenDays.setDate(lastSevenDays.getDate() - 7);

    let startDate: Date;
    let endDate: Date;

    switch (range) {
      case "today":
        startDate = today;
        endDate = today;
        break;
      case "yesterday":
        startDate = yesterday;
        endDate = yesterday;
        break;
      case "lastSevenDays":
        startDate = lastSevenDays;
        endDate = today;
        break;
      default:
        startDate = today;
        endDate = today;
        break;
    }

    setFromDate && setFromDate(startDate.toISOString());
    setToDate && setToDate(endDate.toISOString());
    setSelectionOrder("to");
    setIsValidRange(true);
  };

  if (!fromDate || !toDate) {
    return null;
  }

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={{ marginRight: "25px", whiteSpace: 'nowrap' }}>
        Date Range
      </span>
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <span
          style={{
            position: 'absolute',
            right: '5px',
            top: '50%',
            transform: 'translateY(-50%)',
            color: 'blue',
            cursor: 'pointer',
          }}
          title="Select a date range up to 6 months between which you want invoice lines data"
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM11 15V7H13V15H11ZM12 17C11.45 17 11 16.55 11 16C11 15.45 11.45 15 12 15C12.55 15 13 15.45 13 16C13 16.55 12.55 17 12 17Z"
              fill="currentColor"
            />
          </svg>
        </span>
      </div>
      <DateRangePicker
        value={[new Date(fromDate), new Date(toDate)]}
        onChange={(value, event) => {
          if (value && event && event.target) {
            const target = event.target as HTMLElement;
            const { dataset } = target;
            if (dataset && dataset.range) {
              handleSpecialRange(dataset.range);
            } else {
              const startDate = value[0];
              const endDate = value[1];
              const sixMonthsAfter = computeSixMonthsAfterDate(startDate);
              const isValid = sixMonthsAfter >= endDate;

              if (isValid) {
                if (selectionOrder === "from") {
                  setFromDate && setFromDate(startDate.toISOString());
                  setSelectionOrder("to");
                } else {
                  setToDate && setToDate(endDate.toISOString());
                  setSelectionOrder("from");
                }
                handleApplyDateRange(startDate, endDate);
              } else {
                setIsValidRange(false);
                toast.error("Please enter a date range within 6 months.");
              }
            }
          }
        }}
        showOneCalendar={false}
        format="yyyy-MM-dd"
        cleanable={false}
        shouldDisableDate={shouldDisableDate}
        ranges={[
          {
            label: "Today",
            value: [new Date(), new Date()]
          },
          {
            label: "Yesterday",
            value: [new Date(new Date().setDate(new Date().getDate() - 1)), new Date(new Date().setDate(new Date().getDate() - 1))]
          },
          {
            label: "Last 7 days",
            value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()]
          }
        ]}
      />
      <ToastContainer position="bottom-right" autoClose={5000} />
    </div>
  );
};

export default DateRangePickerComp;

import React, { useMemo, useState } from "react";
import { useFeatureFlagQuery, useNetsuiteTransactionsQuery } from "../../hasura.graphql";
import Loader from "../../common/loader";
import { useCDPRole } from "../../cdp-role-context";
import PageTable from "../../common/page-table";
import ErrorBoundary from "../../components/error-boundary";
import { useLocation, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import TableRowsFilter from "../../common/filters/table-rows-filter";
import moment from "moment";

const Reports: React.FC = () => {
  const { setCDPRole } = useCDPRole();
  const [fromDate, setFromDate] = useState<string>(getFromDate("3"));
  const [toDate, setToDate] = useState<string>(getToDate());
  const [deliverySearch, setDeliverySearch] = useState<string>("");
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const reportsTab = [
    {
      label: "Invoice Lines",
      url: "/reports/invoice-lines",
      active: pathname === "/reports/invoice-lines",
    },
    {
      label: "Product/Services SKU",
      url: "/reports/product-skus",
      active: pathname === "/reports/product-skus",

      
    }
  ];
  const columns: any = useMemo(
    () => [
      {
        Header: "AMAGI ID",
        accessor: "amagi_id",
        show: true,
      
      },
      {
        Header: "CUSTOMER NAME",
        accessor: "customer__name",
        show: true,
      },

      {
        Header: "INVOICE NUMBER",
        accessor: "invoice_number",
        show: true,
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      
      {
        Header: "INVOICE DATE",
        accessor: "invoice_date",
        show: true,
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
        {
        Header: "SKU CODE",
        accessor: "product_code",
        show: true,
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "SKU NAME",
        accessor: "product_name",
        show: true,
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "CHANNEL",
        accessor: "channel_name",
        show: true,
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "PLATFORM",
        accessor: "platform_name",
        show: true,
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "START DATE",
        accessor: "invoice_start_date",
        show: true,
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "END DATE",
        accessor: "invoice_end_date",
        show: true,
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },

      {
        Header: "QUANTITY",
        accessor: "quantity",
        show: true,
        Cell: ({ value }: { value: number }) => <div className="text-right">{value}</div>,
        
      },

      {
        Header: "BILLED AMOUNT",
        accessor: "billed_amount",
        show: true,
        Cell: ({ value }: { value: number }) => <div className="text-right">{value}</div>,
      },

      {
        Header: "CURRENCY",
        accessor: "currency",
        show: true,
         Cell: ({ value }: { value: number }) => <div className="text-right">{value}</div>,
      },
    ],
    []
  );


  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const role_data = useFeatureFlagQuery({
    onCompleted: (data) => {
      setCDPRole(data.feature_flag.map((feat) => feat.feature));
    },
  });
  

  const { data, loading, error } = useNetsuiteTransactionsQuery({
    variables: { start_date: fromDate, end_date: toDate },
  });

  if (loading) {
    return (
      <>
        <div
          className={`hidden sm:block sticky top-14 z-20 bg-gradient-to-r from-gradientBlue via-gradientBlue to-gradientPurple w-full`}
        >
          <div className="border-b border-gray-200 rounded-t-md">
            <nav className="-mb-px px-6 py-1 flex space-x-8" aria-label="Tabs">
              {reportsTab.map((tab) => {
                return (
                  <div
                    onClick={() => {
                      navigate(tab.url);
                    }}
                    className={`${
                      tab.active
                        ? "text-orange-500"
                        : "text-white hover:scale-105 transition-all"
                    } whitespace-nowrap p-2 font-medium text-sm cursor-pointer`}
                  >
                    {tab.label}
                  </div>
                );
              })}
            </nav>
          </div>
        </div>
        <div className="mx-6 mt-4">
          <Loader />
        </div>
      </>
    );
  }
  if (error) {
    return <i>Error</i>;
  }
  if (!data) {
    return <i>Reports data not available</i>;
  }

  const Invoicelines = data.netsuite_transaction_line;

  // Filtering and mapping logic
  const filteredInvoiceLines = Invoicelines.filter((rep) => {
    if (!deliverySearch) {
      return true;
    }
    return `${rep.netsuite_transaction?.customer?.amagi_id} ${rep.netsuite_transaction?.customer?.account_legal_name} ${rep.netsuite_transaction?.transaction_id} ${rep.netsuite_transaction?.billing_period} ${rep.cdpderived_product_id} ${rep.product_name} ${rep.channel} ${rep.platform} ${rep.start_date} ${rep.end_date} ${rep.quantity} ${rep.currency} ${rep.amount_usd}`
      .toLowerCase()
      .includes(deliverySearch.toLowerCase());
  }).map((rep) => {
    return {
      amagi_id: rep.netsuite_transaction?.customer?.amagi_id,
      customer__name: rep.netsuite_transaction?.customer?.account_legal_name,
      invoice_number: rep.netsuite_transaction?.transaction_id || "-",
      invoice_date: rep.netsuite_transaction?.billing_period,
      product_name: rep.product_name,
      product_code: rep.cdpderived_product_id,
      channel_name: rep.channel,
      platform_name: rep.platform,
      invoice_start_date: rep.start_date,
      invoice_end_date: rep.end_date,
      quantity: rep.quantity,
      billed_amount: rep.amount_usd,
      currency: rep.currency
    };
  });

  const exportHeaders = [
    { label: "AMAGI ID", key: "amagi_id" },
    { label: "Customer Name", key: "customer__name" },
    { label: "Invoice Number", key: "invoice_number" },
    { label: "Invoice Date", key: "invoice_date" },
    { label: "SKU Number", key: "product_code" },
    { label: "SKU Name", key: "product_name" },
    { label: "Channel Name", key: "channel_name" },
    { label: "Platform Name", key: "platform_name" },
    { label: "Start Date", key: "invoice_start_date" },
    { label: "End Date", key: "invoice_end_date" },
    { label: "Quantity", key: "quantity" },
    { label: "Currency", key: "currency" },
    { label: "Billed Amount", key: "billed_amount" }

  ];

  return (
    <ErrorBoundary>
      <div
        className={`hidden sm:block sticky top-14 z-20 bg-gradient-to-r from-gradientBlue via-gradientBlue to-gradientPurple w-full`}
      >
        <div className="border-b border-gray-200 rounded-t-md">
          <nav className="-mb-px px-6 py-1 flex space-x-8" aria-label="Tabs">
            {reportsTab.map((tab) => {
              return (
                <div
                  onClick={() => {
                    navigate(tab.url);
                  }}
                  className={`${
                    tab.active
                      ? "text-orange-500"
                      : "text-white hover:scale-105 transition-all"
                  } whitespace-nowrap p-2 font-medium text-sm cursor-pointer`}
                >
                  {tab.label}
                </div>
              );
            })}
          </nav>
        </div>
      </div>
      <div className="mx-6">
        <PageTable
          columns={columns}
          height={window.innerHeight - 245}
          header="Invoice Lines"
          fromDate={fromDate}
          toDate={toDate}
          setFromDate={setFromDate}
          setToDate={setToDate}
          search={deliverySearch}
          setSearch={setDeliverySearch}
          rowData={filteredInvoiceLines}
          warning="Invoice Lines"
          exportHeaders={exportHeaders}
          fileName={`CDP_Invoice_Lines_${moment().format("YYYYMMDDHHmmss")}.csv`} 
          placeholder="Search for customer Invoice Lines"
          showColumnMenu={false}
             />
      </div>
    </ErrorBoundary>
  );
};

function getFromDate(monthRange: string) {
  return DateTime.local()
    .startOf("month")
    .minus({ months: parseInt(monthRange) })
    .toISO()
    .slice(0, 10);
}

function getToDate() {
  const today = DateTime.local();
  const endOfMonth = today.endOf("month");
  const toDate = endOfMonth <= today ? endOfMonth : today;
  return toDate.toISODate();
}

export default Reports;
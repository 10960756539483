import { ChevronRightIcon } from "@heroicons/react/outline";
import { Form, Formik } from "formik";
import { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useCDPRole } from "../../../cdp-role-context";
import {
  ChanelFieldsFragment,
  LinearChannelsDocument,
  useUpdateChannelMutation,
} from "../../../hasura.graphql";
import ChannelBusinessFieldsForm from "./business-fields-form";
import ChannelLegalRightsForm from "./legal-rights-form";
import ChannelTechnicalSpecsForm from "./technical-specs-form";
import { ChannelTrailersType } from "./types";
import SubTabs from "../../sub-tabs";
import { useGetRedundantChannelNamesQuery,useUpsertRedundantPlayoutChannelMutation,useDeleteRedundantPlayoutChannelMutation } 
from "../../../hasura.graphql";
import { combinedList, deletedItems } from "../../formik/multi-select";

type CustomerType = {
  amagi_id: string | null | undefined;
  account_name: string | null | undefined;
};

type ChannelType = {
  salesforce_id: string | null | undefined;
  source: string | null | undefined;
};

const ChannelEditForm: React.FC<{
  channelData: ChanelFieldsFragment;
  customers: Array<CustomerType>;
  channels: Array<ChannelType>;
  isModal: boolean;
  close: () => void;
  header?: string;
  url?: string;
}> = ({ customers, channels, channelData, isModal, close, header, url }) => {
  const navigate = useNavigate();
  const [channelTab, setChannelTab] = useState("Business Specs");

  const [postersList, setPostersList] = useState<Array<string>>(
    channelData?.posters
  );
  const [trailers, setTrailers] = useState<Array<ChannelTrailersType>>(
    channelData?.videos
  );
  const [presentationsList, setPresentationsList] = useState<Array<string>>(
    channelData?.presentations
  );
  const { CDPRole } = useCDPRole();
  const [update_channel] = useUpdateChannelMutation({
    refetchQueries: [
      {
        query: LinearChannelsDocument,
        variables: {
          amagi_id: channelData?.amagi_id_of_channel_owner,
        },
      },
    ],
  });

  const amagiIds = customers.map((cus) => {
    return {
      label: `${cus.amagi_id} - ${cus.account_name}` || "",
      value: cus.amagi_id || "",
    };
  });

  const channelIds = channels.map((chn) => {
    return {
      label: chn.salesforce_id || "",
      value: chn.salesforce_id || "",
    };
  });


  const { data: redundantChannelData, refetch: refetchRedundantChannels } = useGetRedundantChannelNamesQuery({
    variables: {
      args: {
        input_channel_id: channelData.id 
      },
    },
  });
  const redundantChannels = redundantChannelData?.get_redundant_channel_names[0]?.redundant_playout_channels || [];

  const [upsertRedundantPlayoutChannel] = useUpsertRedundantPlayoutChannelMutation();
  const [deleteRedundantPlayoutChannel] = useDeleteRedundantPlayoutChannelMutation();
 
  return (
    <div className={`${!isModal && "mx-6"}`}>
      <Toaster />
      <Formik
        initialValues={{
          channel_id: channelData?.id ? channelData?.id : null,
          channel_brand: channelData?.channel_brand
          ? channelData?.channel_brand
          : null,
          salesforce_id: channelData?.salesforce_id
            ? channelData?.salesforce_id
            : null,
          customer: channelData?.customer,
          multiple_salesforce_id: channelData?.multiple_salesforce_id
            ? channelData?.multiple_salesforce_id
            : [],
          name: channelData?.name ? channelData?.name : null,
          keyring: channelData?.keyring ? channelData?.keyring : [],
          description: channelData?.description ? channelData?.description : [],
          amagi_id_of_channel_owner: channelData?.customer?.amagi_id
            ? channelData?.customer?.amagi_id
            : null,
          channel_origin_country_region:
            channelData?.channel_origin_country_region
              ? channelData?.channel_origin_country_region
              : null,
          availablity_country_and_regions:
            channelData?.availablity_country_and_regions
              ? channelData?.availablity_country_and_regions
              : [],
          genre: channelData?.genre ? channelData?.genre : [],
          sub_genre: channelData?.sub_genre ? channelData?.sub_genre : [],
          fast: channelData?.fast ? channelData?.fast : null,
          is_redundant:
            channelData?.is_redundant !== undefined
              ? channelData?.is_redundant
              : null,
          channel_type: channelData?.channel_type
            ? channelData?.channel_type
            : null,
          subtitle_support:
            channelData?.subtitle_support !== undefined
              ? channelData?.subtitle_support
              : null,
          subtitle_type: channelData?.subtitle_type
            ? channelData?.subtitle_type
            : null,
          subtitle_language: channelData?.subtitle_language
            ? channelData?.subtitle_language
            : [],
          feed_type: channelData?.feed_type ? channelData?.feed_type : null,
          original_audio_language: channelData?.original_audio_language
            ? channelData?.original_audio_language
            : null,
          channel_rating: channelData?.channel_rating
            ? channelData?.channel_rating
            : [],
          channel_resolution: channelData?.channel_resolution
            ? channelData?.channel_resolution
            : null,
          redundant_playout_channels: redundantChannels,
          videos: trailers,
          logo: channelData?.logo ? channelData?.logo : null,
          posters: postersList,
          presentations: presentationsList,
          instagram: channelData?.instagram ? channelData?.instagram : null,
          facebook: channelData?.facebook ? channelData?.facebook : null,
          twitter: channelData?.twitter ? channelData?.twitter : null,
          linkedin: channelData?.linkedin ? channelData?.linkedin : null,
          target_audience: channelData?.target_audience
            ? channelData?.target_audience
            : [],
          custom_fields: channelData?.custom_fields
            ? channelData?.custom_fields
            : [],
          custom_tags: channelData?.custom_tags ? channelData?.custom_tags : [],
          ssai_partner: channelData?.ssai_partner
            ? channelData?.ssai_partner
            : null,
          channel_start_time: channelData?.channel_start_time
            ? channelData?.channel_start_time
            : null,
          channel_end_time: channelData?.channel_end_time
            ? channelData?.channel_end_time
            : null,
          paid_flag:
            channelData?.paid_flag !== undefined
              ? channelData?.paid_flag
              : null,
          ad_flag:
            channelData?.ad_flag !== undefined ? channelData?.ad_flag : null,
          promotion_flag:
            channelData?.promotion_flag !== undefined
              ? channelData?.promotion_flag
              : null,
          is_contentplus_channel:
            channelData?.is_contentplus_channel !== undefined
              ? channelData?.is_contentplus_channel
              : null,
          is_adsplus_channel:
            channelData?.is_adsplus_channel !== undefined
              ? channelData?.is_adsplus_channel
              : null,
          subtitling:
            channelData?.subtitling !== undefined
              ? channelData?.subtitling
              : null,
          closed_captioning:
            channelData?.closed_captioning !== undefined
              ? channelData?.closed_captioning
              : null,
          dubbing:
            channelData?.dubbing !== undefined ? channelData?.dubbing : null,
          dubbing_language: channelData?.dubbing_language
            ? channelData?.dubbing_language
            : [],
          sponsorship:
            channelData?.sponsorship !== undefined
              ? channelData?.sponsorship
              : null,
          trademark_and_logo:
            channelData?.trademark_and_logo !== undefined
              ? channelData?.trademark_and_logo
              : null,
          create_promotional:
            channelData?.create_promotional !== undefined
              ? channelData?.create_promotional
              : null,
          edit_promotional:
            channelData?.edit_promotional !== undefined
              ? channelData?.edit_promotional
              : null,
          create_short_form_video:
            channelData?.create_short_form_video !== undefined
              ? channelData?.create_short_form_video
              : null,
          create_pop_channel:
            channelData?.create_pop_channel !== undefined
              ? channelData?.create_pop_channel
              : null,
          use_of_display_technology:
            channelData?.use_of_display_technology !== undefined
              ? channelData?.use_of_display_technology
              : null,
          time_zone: channelData?.time_zone ? channelData?.time_zone : null,
          start_time: channelData?.start_time ? channelData?.start_time : null,
          playout_format: channelData?.playout_format
            ? channelData?.playout_format
            : null,
          video_format: channelData?.video_format
            ? channelData?.video_format
            : null,
          audio_format: channelData?.audio_format
            ? channelData?.audio_format
            : null,
          playlist_format: channelData?.playlist_format
            ? channelData?.playlist_format
            : null,
          metadata_ingest_format: channelData?.metadata_ingest_format
            ? channelData?.metadata_ingest_format
            : null,
          content_ingest_format: channelData?.content_ingest_format
            ? channelData?.content_ingest_format
            : null,
          live_playout:
            channelData?.live_playout !== undefined
              ? channelData?.live_playout
              : null,
          live_feed_input:
            channelData?.live_feed_input !== undefined
              ? channelData?.live_feed_input
              : null,
          live_recording:
            channelData?.live_recording !== undefined
              ? channelData?.live_recording
              : null,
          live_management:
            channelData?.live_management !== undefined
              ? channelData?.live_management
              : null,
          closed_caption:
            channelData?.closed_caption !== undefined
              ? channelData?.closed_caption
              : null,
          closed_caption_type: channelData?.closed_caption_type
            ? channelData?.closed_caption_type
            : null,
          closed_caption_language: channelData?.closed_caption_language
            ? channelData?.closed_caption_language
            : [],
          amagi_auto_cc_generation:
            channelData?.amagi_auto_cc_generation !== undefined
              ? channelData?.amagi_auto_cc_generation
              : null,
          graphics_enabled:
            channelData?.graphics_enabled !== undefined
              ? channelData?.graphics_enabled
              : null,
          graphics_type: channelData?.graphics_type
            ? channelData?.graphics_type
            : null,
          channel_logo_bug:
            channelData?.channel_logo_bug !== undefined
              ? channelData?.channel_logo_bug
              : null,
          graphics_format: channelData?.graphics_format
            ? channelData?.graphics_format
            : null,
        }}
        onSubmit={async ({
          customer,
          channel_id,
          videos,
          posters,
          presentations,
          redundant_playout_channels,
          ...values
        }) => {
          try {
            await update_channel({
              variables: {
                id: channelData?.id,
                input: {
                  videos: trailers,
                  posters: postersList,
                  presentations: presentationsList,
                  ...values,
                },
              },
            });
            for (const channelString of combinedList) {
              const channelId = channelString.split(':')[0].trim(); // Extracting channel ID
              // Check if channel is present in deletedItems list
              if (deletedItems.includes(channelString)) {
                // Call deletion mutation
                await deleteRedundantPlayoutChannel({
                  variables: {
                    RedundantId: channelId,
                    channelId: channelData.id,
                  },
                });
              } else {
                // Call upsert mutation
                await upsertRedundantPlayoutChannel({
                  variables: {
                    RedundantId: channelId,
                    channelId: channelData.id,
                  },
                });
              }
            }
            toast.success("Updated successfully");
            close();
            // Refetch the redundant channels data
            refetchRedundantChannels();
            setPostersList([]);
            setTrailers([]);
            setPresentationsList([]);
          } catch (e) {
            toast.error(`There was an error ${e}`);
            console.log(e);
          }
          console.log(values, posters, trailers, presentations);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form
            style={{ color: "#4E4E4E", fontSize: "14px", fontWeight: "500" }}
          >
            {isModal !== true ? (
              <>
                <div className="sticky top-14 z-20 text-base">
                  <div className="flex justify-between items-center sticky top-14 h-14 w-full bg-secondaryBg">
                    <div className="flex items-center">
                      <span
                        onClick={() => navigate(`${url}`)}
                        className="font-medium text-gray-600 cursor-pointer hover:text-blue-500 hover:underline"
                      >
                        {header}
                      </span>
                      <ChevronRightIcon className="h-5 w-5 mx-3 text-gray-400" />
                      <span>{channelData?.name}</span>
                    </div>
                    <div className="flex justify-end">
                      <button
                        type="submit"
                        className="py-1 px-3 bg-primaryColor text-white rounded-sm"
                      >
                        Submit
                      </button>
                      <button
                        className="py-1 px-3 text-primaryColor"
                        onClick={() => navigate(`${url}/${channelData?.id}`)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                <div className="mb-4 sticky top-28 z-20">
                  <SubTabs
                    customStyles=""
                    currentTab={channelTab}
                    tabList={[
                      { name: "Business Specs" },
                      { name: "Channel Legal Rights" },
                      { name: "Technical Specs" },
                    ]}
                    toggleTab={setChannelTab}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="mb-2 sticky bg-secondaryBg top-12 z-20 flex justify-between">
                  <div>
                    <SubTabs
                      customStyles=""
                      currentTab={channelTab}
                      tabList={[
                        { name: "Business Specs" },
                        { name: "Channel Legal Rights" },
                        { name: "Technical Specs" },
                      ]}
                      toggleTab={setChannelTab}
                    />
                  </div>
                  {CDPRole.includes("channel-edit") && (
                    <div>
                      <button
                        type="submit"
                        className="py-1 px-3 bg-primaryColor text-white rounded-sm"
                      >
                        Submit
                      </button>
                      <button
                        className="py-1 px-3 text-primaryColor"
                        onClick={() => close()}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              </>
            )}

            <div className="w-full">
              {channelTab === "Business Specs" && (
                <ChannelBusinessFieldsForm
                  values={values}
                  amagiIds={amagiIds}
                  channelIds={channelIds}
                  setFieldValue={setFieldValue}
                  posters={postersList}
                  videos={trailers}
                  presentations={presentationsList}
                  setPosters={setPostersList}
                  setTrailers={setTrailers}
                  setPresentations={setPresentationsList}
                  redundant_playout_channels={redundantChannels}
                />
              )}
              {channelTab === "Channel Legal Rights" && (
                <ChannelLegalRightsForm
                  values={values}
                  channelData={channelData!}
                />
              )}
              {channelTab === "Technical Specs" && (
                <ChannelTechnicalSpecsForm values={values} />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ChannelEditForm;

import { useMemo, useState } from "react";
import { ChannelGridFragment } from "../../hasura.graphql";
import { ModalType } from "../../pages/channels";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import PageTable from "../../common/page-table";
import moment from "moment";
import Modal from "../modal";
import ChannelViewForm from "./channel-view-form";
import TableRowsFilter from "../../common/filters/table-rows-filter";

const ChannelList: React.FC<{
  channels: Array<ChannelGridFragment>;
  channelSearch: string;
  setChannelSearch: React.Dispatch<React.SetStateAction<string>>;
  toggleView: string;
  setToggleView: React.Dispatch<React.SetStateAction<string>>;
  header?: string;
  url?: string;
}> = ({
  channels,
  channelSearch,
  setChannelSearch,
  toggleView,
  setToggleView,
  header,
  url,
}) => {
  const [channelId, setChannelId] = useState<ModalType | null>(null);
  const filteredChannels = channels.map((chn) => {
    return {
      id: chn.id,
      name: chn.name,
      channel_brand: chn.channel_brand,
      ordered_channel: chn.ordered_channel,
      customer_id: chn.customer?.amagi_id,
      account_name: chn.customer?.account_name,
      customer_status: chn.customer?.status,
      genre: chn.genre.length > 0 ? chn.genre : "--",
      channel_logo: chn.logo,
      customer_logo: chn.customer?.logo,
      feed_type: chn.feed_type || "--",
      platform_delivered_to:
        chn.platform_delivered_to && chn.platform_delivered_to.length > 0
          ? chn.platform_delivered_to
          : [],
      fast: chn.fast || "--",
    };
  });

  const columns: any = useMemo(
    () => [
      {
        Header: "CHANNEL ID",
        accessor: "id",
        show: false,
      },
      {
        Header: "CHANNEL NAME",
        accessor: "name",
        Cell: ({ cell }: any) => (
          <div className="flex">
            <div className="w-16 h-12">
              {cell.row.original.channel_logo ? (
                <LazyLoadImage
                  className="h-full w-full border rounded-lg object-contain bg-lightPrimary"
                  src={
                    cell.row.original.channel_logo
                      ? `/${cell.row.original.channel_logo}`
                      : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${cell.row.original.name}`
                  }
                  alt={cell.row.original.channel_logo}
                />
              ) : (
                <div className="w-full h-full rounded-lg flex font-semibold text-xl text-gray-300 items-center justify-center object-contain border-b bg-gradient-to-r from-gradientBlue to-gradientOrange">
                  {cell.row.original.name[0]}
                </div>
              )}
            </div>
            <div className="ml-3 flex flex-col">
              <div className="flex">
                <span
                  className="cursor-pointer mr-2 hover:text-blue-500 hover:underline"
                  onClick={() => {
                    setChannelId({
                      id: cell.row.original.id,
                      name: cell.row.original.name,
                    });
                    // setAmagiId(cell.row.original.customer_id || "-");
                  }}
                >
                  {cell.row.values.name.length > 40
                    ? `${cell.row.values.name.slice(0, 40)}...`
                    : cell.row.values.name}
                </span>
                <a
                  href={`${url}/${cell.row.original.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ExternalLinkIcon className="h-5 w-5 text-blue-500" />
                </a>
              </div>
              <span className="text-sm text-gray-500">
                {cell.row.original.id}
              </span>
            </div>
          </div>
        ),
        show: true,
      },
      {
        Header: "CHANNEL BRAND",
        accessor: "channel_brand",
        show: true,
      },
      {
        Header: "CUSTOMER ID",
        accessor: "customer_id",
        show: false,
      },
      {
        Header: "CUSTOMER NAME",
        accessor: "account_name",
        Cell: ({ cell }: any) => (
          <div className="flex">
            {cell.row.original.account_name && (
              <>
                <div className="w-16 h-12">
                  {cell.row.original.customer_logo ? (
                    <LazyLoadImage
                      className="h-full w-full border rounded-lg object-contain bg-lightPrimary"
                     src={
                            cell.row.original.customer_logo.startsWith("uploads/")
                              ? `/${cell.row.original.customer_logo}`
                              : cell.row.original.customer_logo
                              ? `/logos/${cell.row.original.customer_logo}`
                              : `https://via.placeholder.com/300x200.png/C1CFFB/1924C1?text=${cell.row.original.account_name}`
                          }
                      alt={cell.row.original.customer_logo}
                    />
                  ) : (
                    <div className="w-full h-full rounded-lg flex font-semibold text-xl text-gray-300 items-center justify-center object-contain border-b bg-gradient-to-r from-gradientBlue to-gradientOrange">
                      {cell.row.original.account_name[0]}
                    </div>
                  )}
                </div>
              </>
            )}
            <div className="ml-3 flex flex-col">
              <a
                href={`/customers/${cell.row.original.customer_id}/details`}
                target="_blank"
                rel="noreferrer"
                className="hover:text-blue-500 hover:underline"
              >
                {cell.row.values.account_name}
              </a>
              <span className="text-sm text-gray-500">
                {cell.row.original.customer_id}
              </span>
            </div>
          </div>
        ),
        Filter: TableRowsFilter,
        filter: "multiSelect",
        show: true,
      },
      {
        Header: "GENRE",
        accessor: "genre",
        Cell: ({ cell }: any) => (
          <div
            className="p-2 flex overflow-hidden pt-2"
            style={{ fontSize: "12px" }}
          >
            {typeof cell.row.original.genre === "object" ? (
              cell.row.original.genre.map((g: string, index: number) => {
                if (index < 3) {
                  return (
                    <span
                      key={index}
                      className="border border-gray-300 px-2 mr-2 rounded-full"
                    >
                      {g}
                    </span>
                  );
                } else if (index === 3) {
                  return (
                    <span
                      key={index}
                      className="border border-gray-300 px-2 mr-2 rounded-full"
                    >{`+ ${
                      cell.row.original.genre.length - (index + 1)
                    }`}</span>
                  );
                } else return null;
              })
            ) : (
              <span className="ml-16">{cell.row.original.genre}</span>
            )}
          </div>
        ),
        show: true,
      },
      {
        Header: "FEED TYPE",
        accessor: "feed_type",
        Filter: TableRowsFilter,
        filter: "multiselect",
        show: true,
      },
      {
        Header: "PLATFORMS DELIVERED TO",
        accessor: "platform_delivered_to",
        Filter: TableRowsFilter,
        filter: "multiselect",
        show: true,
      },
      {
        Header: "FAST",
        accessor: "fast",
        Filter: TableRowsFilter,
        filter: "multiselect",
        show: true,
      },
    ],
    [url]
  );

  const hideColumns = ["feed_type", "platform_delivered_to", "fast"];

  return (
    <>
      <PageTable
        toggleView={toggleView}
        isExternal={true}
        header={header}
        isChannel={true}
        height={window.innerHeight - 245}
        search={channelSearch}
        setSearch={setChannelSearch}
        setToggleView={setToggleView}
        columns={columns}
        warning="Channels"
        hideColumns={hideColumns}
        rowData={filteredChannels}
        exportHeaders={columns.map((col: any) => ({
          label: col.Header,
          key: col.accessor,
        }))}
        placeholder="Search by channel, customer and genre"
        fileName={`CDP_Channels_${moment().format("YYYYMMDDHHmmss")}.csv`} 
        setFromDate={null}
        setToDate={null}
      />
      {channelId && (
        <Modal
          title={`All ${header}`}
          modalWidth="100vw"
          modalShow={channelId}
          onClose={setChannelId}
        >
          <ChannelViewForm
            channelId={channelId.id}
            isModal={true}
            header={`All ${header}`}
            url="/channels/grouped"
          />
        </Modal>
      )}
    </>
  );
};

export default ChannelList;

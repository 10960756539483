export const channelFields = {
  channelType: ["Live", "Linear", "Pop-up"],
  contentType: ["Movies", "Music", "News", "Sports", "TV Series"],
  ssaiPartner: ["Amagi", "Other"],
  subtitleType: [
    ".srt",
    ".vtt",
    ".stl",
    ".scc",
    ".mcc",
    ".ass",
    ".890",
    ".fpc",
    ".pac",
    ".xml",
    ".dvb",
  ],
  targetAudience: ["Family", "Kids", "Men", "Teen", "Women", "Youth"],
  graphicsFormat: [
    "Static graphics / animated sequences",
    "Time-based graphics",
    "Advanced dynamic graphics ",
    "Advanced graphics / Digital Video Effects (DVE)",
    "Real-time text-based graphics",
  ],
  videoFormat: [
    "DV25, DVCPRO 25/50/100",
    "IMX 30/40/50/85",
    "MPEG 2 4:2:0/4.2.2 HD",
    "MPEG 2 4:2:0/4.2.2 SD",
    "MPEG 4/H.264 4:2:0/4:2:2 SD",
    "MPEG 4/H.264 4:2:0/4.2.2 HD",
    "ProRes 422 HQ/Prores 422 LT",
    "XDCAM HD (LP, SP, HQ)",
    "XDCAM HD422",
  ],
  videoFormat2: ["mp4", "mxf", "mov", "mpegts", "Others"],
  // audioFormat: ["AAC", "E-AC3", "MP2", "PCM-AC3", "WAV"],
  audioFormat: [
    "aac",
    "ac-3",
    "mp2",
    "mp4",
    "mpeg audio",
    "pcm",
    "Dolby",
    "Others",
  ],
  playlistFormat: ["Amagi UI", "Amagi Planner", "NA", "Others"],
  metadataIngestFormat: ["MRSS", "CSV", "Others"],
  contentIngestFormat: ["S3", "MRSS", "Others"],
  liveFeedInput: ["SRT", "Zixi", "Others"],
  graphicsType: ["Basic", "Advanced"],
  resolution: ["SD", "HD", "FHD", "4K", "8K"],
  streamContainerType: ["HLS", "MPD"],
  availablity_regions: [
    "APAC (Asia Pacific)",
    "Europe",
    "Middle East",
    "Africa",
    "Latin America",
    "North America",
    "Oceania",
    "Worldwide",
  ],
  feedType: ["Main Feed", "Sub Feed"],
  channelRating: [
    "G",
    "NC-17",
    "PG",
    "PG-13",
    "R",
    "TV-14",
    "TV-G",
    "TV-MA",
    "TV-Y",
    "TV-Y7",
  ],
  // genre: [
  //   "Adult",
  //   "All/Combo",
  //   "Animation",
  //   "Arts",
  //   "Black Stories",
  //   "Comedy",
  //   "Creator (FB, YT, TikTok)",
  //   "Crime",
  //   "Documentary",
  //   "Drama",
  //   "Entertainment",
  //   "Fitness",
  //   "Food",
  //   "Gambling/Betting",
  //   "Game Shows",
  //   "History",
  //   "Horror/Thriller",
  //   "Latino",
  //   "LGBTQ",
  //   "Lifestyle & Culture",
  //   "Reality TV",
  //   "Talk & Interview TV",
  // ],
  genre: [
    "Action",
    "Adventure",
    "All/Combo",
    "Ambient",
    "Animals",
    "Animation",
    "Anime",
    "Arts",
    "Aviation",
    "Black Culture",
    "Black Stories",
    "Bollywood",
    "Business News",
    "Business TV",
    "Cartoons",
    "Celebrity Stories",
    "Classic TV",
    "Classics",
    "Comedy",
    "Cookery",
    "Cooking",
    "Creator (FB, YT, TikTok)",
    "Crime",
    "Current Affairs",
    "Dance Anthem",
    "DIY",
    "Docs and films",
    "Documentary",
    "Drama",
    "E-sports",
    "Educational",
    "Entertainment",
    "Equality",
    "Factual Entertainment",
    "Faith & Religion",
    "Family",
    "Fashion",
    "Fiction",
    "Films",
    "Fitness",
    "Food",
    "Football",
    "Fractual",
    "Gambling/Betting",
    "Games",
    "Games Show",
    "Gaming",
    "Health",
    "Hip-hop",
    "History",
    "Home Improvement",
    "Horror",
    "Horror/Thriller",
    "Infotainment",
    "Investigation",
    "Kids",
    "Latino",
    "Learning",
    "LGBTQ",
    "Lifestyle",
    "Lifestyle & Culture",
    "Movie Trailers",
    "Movies",
    "Music",
    "Music Bios",
    "Mystery",
    "NA",
    "Natural Sciences",
    "Nature",
    "News",
    "Not clear",
    "Performances",
    "Pets & Animals",
    "Physical Transformation",
    "Pop",
    "Pop Culture",
    "Reality shows",
    "Retro",
    "Rock",
    "Romance",
    "Sci-Fi",
    "Science",
    "Science Fiction",
    "Sitcom",
    "Space",
    "Specials & Variety",
    "Sports",
    "Sports News",
    "Suspense",
    "Talk Show",
    "Talk & Interview TV",
    "Technology",
    "Teen",
    "Telenovel",
    "Test",
    "Thriller",
    "Travel",
    "TV Series",
    "Urban Lifestyle",
    "Variety",
    "Viral videos",
    "Weather News",
    "Western",
    "Wildlife",
    "Women",
    "Other",
  ],
  languages: [
    "Afar",
    "Abkhaz",
    "Avestan",
    "Afrikaans",
    "Akan",
    "Amharic",
    "Aragonese",
    "Arabic",
    "Assamese",
    "Avaric",
    "Aymara",
    "Azerbaijani",
    "South Azerbaijani",
    "Bashkir",
    "Belarusian",
    "Bulgarian",
    "Bihari",
    "Bislama",
    "Bambara",
    "Bengali; Bangla",
    "Tibetan Standard, Tibetan, Central",
    "Breton",
    "Bosnian",
    "Catalan; Valencian",
    "Chechen",
    "Chamorro",
    "Corsican",
    "Cree",
    "Czech",
    "Old Church Slavonic, Church Slavonic, Old Bulgarian",
    "Chuvash",
    "Welsh",
    "Danish",
    "German",
    "Divehi; Dhivehi; Maldivian;",
    "Dzongkha",
    "Ewe",
    "Greek",
    "English",
    "Esperanto",
    "Spanish",
    "Estonian",
    "Basque",
    "Persian (Farsi)",
    "Fula; Fulah; Pulaar; Pular",
    "Finnish",
    "Fijian",
    "Faroese",
    "French",
    "Western Frisian",
    "Irish",
    "Scottish Gaelic; Gaelic",
    "Galician",
    "Guarani",
    "Gujarati",
    "Manx",
    "Hausa",
    "Hebrew",
    "Hindi",
    "Hiri Motu",
    "Croatian",
    "Haitian; Haitian Creole",
    "Hungarian",
    "Armenian",
    "Herero",
    "Interlingua",
    "Indonesian",
    "Interlingue",
    "Igbo",
    "Nuosu",
    "Inupiaq",
    "Ido",
    "Icelandic",
    "Italian",
    "Inuktitut",
    "Japanese",
    "Javanese",
    "Georgian",
    "Kongo",
    "Kikuyu, Gikuyu",
    "Kwanyama, Kuanyama",
    "Kazakh",
    "Kalaallisut, Greenlandic",
    "Khmer",
    "Kannada",
    "Korean",
    "Kanuri",
    "Kashmiri",
    "Kurdish",
    "Komi",
    "Cornish",
    "Kyrgyz",
    "Latin",
    "Luxembourgish, Letzeburgesch",
    "Ganda",
    "Limburgish, Limburgan, Limburger",
    "Lingala",
    "Lao",
    "Lithuanian",
    "Luba-Katanga",
    "Latvian",
    "Malagasy",
    "Marshallese",
    "M?ori",
    "Macedonian",
    "Malayalam",
    "Mongolian",
    "Marathi",
    "Malay",
    "Maltese",
    "Burmese",
    "Nauru",
    "North Ndebele",
    "Nepali",
    "Ndonga",
    "Dutch",
    "Norwegian Nynorsk",
    "Norwegian",
    "South Ndebele",
    "Navajo, Navaho",
    "Chichewa; Chewa; Nyanja",
    "Occitan",
    "Ojibwe, Ojibwa",
    "Oromo",
    "Oriya",
    "Ossetian, Ossetic",
    "Panjabi, Punjabi",
    "Pali",
    "Polish",
    "Pashto, Pushto",
    "Portuguese",
    "Quechua",
    "Romansh",
    "Kirundi",
    "Romanian",
    "Russian",
    "Kinyarwanda",
    "Sanskrit",
    "Sardinian",
    "Sindhi",
    "Northern Sami",
    "Sango",
    "Sinhala, Sinhalese",
    "Slovak",
    "Slovene",
    "Samoan",
    "Shona",
    "Somali",
    "Albanian",
    "Serbian",
    "Swati",
    "Southern Sotho",
    "Sundanese",
    "Swedish",
    "Swahili",
    "Tamil",
    "Telugu",
    "Tajik",
    "Thai",
    "Tigrinya",
    "Turkmen",
    "Tagalog",
    "Tswana",
    "Tonga (Tonga Islands)",
    "Turkish",
    "Tsonga",
    "Tatar",
    "Twi",
    "Tahitian",
    "Uyghur, Uighur",
    "Ukrainian",
    "Urdu",
    "Uzbek",
    "Venda",
    "Vietnamese",
    "Walloon",
    "Wolof",
    "Xhosa",
    "Yiddish",
    "Yoruba",
    "Zhuang, Chuang",
    "Chinese",
    "Zulu",
    "None",
    "Worldwide",
  ],
  timeZone: [
    {
      zone: "ACDT",
      name: "Australian Central Daylight Saving Time",
      utc: "UTC+10:30",
    },
    {
      zone: "ACST",
      name: "Australian Central Standard Time",
      utc: "UTC+09:30",
    },
    { zone: "ACT", name: "Acre Time", utc: "UTC-05" },
    { zone: "ACT", name: "ASEAN Common Time (proposed)", utc: "UTC+08:00" },
    {
      zone: "ACWST",
      name: "Australian Central Western Standard Time (unofficial)",
      utc: "UTC+08:45",
    },
    { zone: "ADT", name: "Atlantic Daylight Time", utc: "UTC-03" },
    {
      zone: "AEDT",
      name: "Australian Eastern Daylight Saving Time",
      utc: "UTC+11",
    },
    { zone: "AEST", name: "Australian Eastern Standard Time", utc: "UTC+10" },
    { zone: "AET", name: "Australian Eastern Time", utc: "UTC+10/UTC+11" },
    { zone: "AFT", name: "Afghanistan Time", utc: "UTC+04:30" },
    { zone: "AKDT", name: "Alaska Daylight Time", utc: "UTC-08" },
    { zone: "AKST", name: "Alaska Standard Time", utc: "UTC-09" },
    { zone: "ALMT", name: "Alma-Ata Time[1]", utc: "UTC+06" },
    { zone: "AMST", name: "Amazon Summer Time (Brazil)[2]", utc: "UTC-03" },
    { zone: "AMT", name: "Amazon Time (Brazil)[3]", utc: "UTC-04" },
    { zone: "AMT", name: "Armenia Time", utc: "UTC+04" },
    { zone: "ANAT", name: "Anadyr Time[4]", utc: "UTC+12" },
    { zone: "AQTT", name: "Aqtobe Time[5]", utc: "UTC+05" },
    { zone: "ART", name: "Argentina Time", utc: "UTC-03" },
    { zone: "AST", name: "Arabia Standard Time", utc: "UTC+03" },
    { zone: "AST", name: "Atlantic Standard Time", utc: "UTC-04" },
    { zone: "AWST", name: "Australian Western Standard Time", utc: "UTC+08" },
    { zone: "AZOST", name: "Azores Summer Time", utc: "UTC±00" },
    { zone: "AZOT", name: "Azores Standard Time", utc: "UTC-01" },
    { zone: "AZT", name: "Azerbaijan Time", utc: "UTC+04" },
    { zone: "BNT", name: "Brunei Time", utc: "UTC+08" },
    { zone: "BIOT", name: "British Indian Ocean Time", utc: "UTC+06" },
    { zone: "BIT", name: "Baker Island Time", utc: "UTC-12" },
    { zone: "BOT", name: "Bolivia Time", utc: "UTC-04" },
    { zone: "BRST", name: "Brasília Summer Time", utc: "UTC-02" },
    { zone: "BRT", name: "Brasília Time", utc: "UTC-03" },
    { zone: "BST", name: "Bangladesh Standard Time", utc: "UTC+06" },
    { zone: "BST", name: "Bougainville Standard Time[6]", utc: "UTC+11" },
    {
      zone: "BST",
      name: "British Summer Time (British Standard Time from Feb 1968 to Oct 1971)",
      utc: "UTC+01",
    },
    { zone: "BTT", name: "Bhutan Time", utc: "UTC+06" },
    { zone: "CAT", name: "Central Africa Time", utc: "UTC+02" },
    { zone: "CCT", name: "Cocos Islands Time", utc: "UTC+06:30" },
    {
      zone: "CDT",
      name: "Central Daylight Time (North America)",
      utc: "UTC-05",
    },
    { zone: "CDT", name: "Cuba Daylight Time[7]", utc: "UTC-04" },
    { zone: "CEST", name: "Central European Summer Time", utc: "UTC+02" },
    { zone: "CET", name: "Central European Time", utc: "UTC+01" },
    { zone: "CHADT", name: "Chatham Daylight Time", utc: "UTC+13:45" },
    { zone: "CHAST", name: "Chatham Standard Time", utc: "UTC+12:45" },
    { zone: "CHOT", name: "Choibalsan Standard Time", utc: "UTC+08" },
    { zone: "CHOST", name: "Choibalsan Summer Time", utc: "UTC+09" },
    { zone: "CHST", name: "Chamorro Standard Time", utc: "UTC+10" },
    { zone: "CHUT", name: "Chuuk Time", utc: "UTC+10" },
    { zone: "CIST", name: "Clipperton Island Standard Time", utc: "UTC-08" },
    { zone: "CKT", name: "Cook Island Time", utc: "UTC-10" },
    { zone: "CLST", name: "Chile Summer Time", utc: "UTC-03" },
    { zone: "CLT", name: "Chile Standard Time", utc: "UTC-04" },
    { zone: "COST", name: "Colombia Summer Time", utc: "UTC-04" },
    { zone: "COT", name: "Colombia Time", utc: "UTC-05" },
    {
      zone: "CST",
      name: "Central Standard Time (North America)",
      utc: "UTC-06",
    },
    { zone: "CST", name: "China Standard Time", utc: "UTC+08" },
    { zone: "CST", name: "Cuba Standard Time", utc: "UTC-05" },
    { zone: "CT", name: "Central Time", utc: "UTC-06/UTC-05" },
    { zone: "CVT", name: "Cape Verde Time", utc: "UTC-01" },
    {
      zone: "CWST",
      name: "Central Western Standard Time (Australia) unofficial",
      utc: "UTC+08:45",
    },
    { zone: "CXT", name: "Christmas Island Time", utc: "UTC+07" },
    { zone: "DAVT", name: "Davis Time", utc: "UTC+07" },
    { zone: "DDUT", name: "Dumont d'Urville Time", utc: "UTC+10" },
    {
      zone: "DFT",
      name: "AIX-specific equivalent of Central European Time[NB 1]",
      utc: "UTC+01",
    },
    { zone: "EASST", name: "Easter Island Summer Time", utc: "UTC-05" },
    { zone: "EAST", name: "Easter Island Standard Time", utc: "UTC-06" },
    { zone: "EAT", name: "East Africa Time", utc: "UTC+03" },
    {
      zone: "ECT",
      name: "Eastern Caribbean Time (does not recognise DST)",
      utc: "UTC-04",
    },
    { zone: "ECT", name: "Ecuador Time", utc: "UTC-05" },
    {
      zone: "EDT",
      name: "Eastern Daylight Time (North America)",
      utc: "UTC-04",
    },
    { zone: "EEST", name: "Eastern European Summer Time", utc: "UTC+03" },
    { zone: "EET", name: "Eastern European Time", utc: "UTC+02" },
    { zone: "EGST", name: "Eastern Greenland Summer Time", utc: "UTC±00" },
    { zone: "EGT", name: "Eastern Greenland Time", utc: "UTC-01" },
    {
      zone: "EST",
      name: "Eastern Standard Time (North America)",
      utc: "UTC-05",
    },
    { zone: "ET", name: "Eastern Time (North America)", utc: "UTC-05/UTC-04" },
    { zone: "FET", name: "Further-eastern European Time", utc: "UTC+03" },
    { zone: "FJT", name: "Fiji Time", utc: "UTC+12" },
    { zone: "FKST", name: "Falkland Islands Summer Time", utc: "UTC-03" },
    { zone: "FKT", name: "Falkland Islands Time", utc: "UTC-04" },
    { zone: "FNT", name: "Fernando de Noronha Time", utc: "UTC-02" },
    { zone: "GALT", name: "Galápagos Time", utc: "UTC-06" },
    { zone: "GAMT", name: "Gambier Islands Time", utc: "UTC-09" },
    { zone: "GET", name: "Georgia Standard Time", utc: "UTC+04" },
    { zone: "GFT", name: "French Guiana Time", utc: "UTC-03" },
    { zone: "GILT", name: "Gilbert Island Time", utc: "UTC+12" },
    { zone: "GIT", name: "Gambier Island Time", utc: "UTC-09" },
    { zone: "GMT", name: "Greenwich Mean Time", utc: "UTC±00" },
    {
      zone: "GST",
      name: "South Georgia and the South Sandwich Islands Time",
      utc: "UTC-02",
    },
    { zone: "GST", name: "Gulf Standard Time", utc: "UTC+04" },
    { zone: "GYT", name: "Guyana Time", utc: "UTC-04" },
    { zone: "HDT", name: "Hawaii-Aleutian Daylight Time", utc: "UTC-09" },
    {
      zone: "HAEC",
      name: "Heure Avancée d'Europe Centrale French-language name for CEST",
      utc: "UTC+02",
    },
    { zone: "HST", name: "Hawaii-Aleutian Standard Time", utc: "UTC-10" },
    { zone: "HKT", name: "Hong Kong Time", utc: "UTC+08" },
    { zone: "HMT", name: "Heard and McDonald Islands Time", utc: "UTC+05" },
    {
      zone: "HOVST",
      name: "Hovd Summer Time (not used from 2017-present)",
      utc: "UTC+08",
    },
    { zone: "HOVT", name: "Hovd Time", utc: "UTC+07" },
    { zone: "ICT", name: "Indochina Time", utc: "UTC+07" },
    {
      zone: "IDLW",
      name: "International Day Line West time zone",
      utc: "UTC-12",
    },
    { zone: "IDT", name: "Israel Daylight Time", utc: "UTC+03" },
    { zone: "IOT", name: "Indian Ocean Time", utc: "UTC+03" },
    { zone: "IRDT", name: "Iran Daylight Time", utc: "UTC+04:30" },
    { zone: "IRKT", name: "Irkutsk Time", utc: "UTC+08" },
    { zone: "IRST", name: "Iran Standard Time", utc: "UTC+03:30" },
    { zone: "IST", name: "Indian Standard Time", utc: "UTC+05:30" },
    { zone: "IST", name: "Irish Standard Time[8]", utc: "UTC+01" },
    { zone: "IST", name: "Israel Standard Time", utc: "UTC+02" },
    { zone: "JST", name: "Japan Standard Time", utc: "UTC+09" },
    { zone: "KALT", name: "Kaliningrad Time", utc: "UTC+02" },
    { zone: "KGT", name: "Kyrgyzstan Time", utc: "UTC+06" },
    { zone: "KOST", name: "Kosrae Time", utc: "UTC+11" },
    { zone: "KRAT", name: "Krasnoyarsk Time", utc: "UTC+07" },
    { zone: "KST", name: "Korea Standard Time", utc: "UTC+09" },
    { zone: "LHST", name: "Lord Howe Standard Time", utc: "UTC+10:30" },
    { zone: "LHST", name: "Lord Howe Summer Time", utc: "UTC+11" },
    { zone: "LINT", name: "Line Islands Time", utc: "UTC+14" },
    { zone: "MAGT", name: "Magadan Time", utc: "UTC+12" },
    { zone: "MART", name: "Marquesas Islands Time", utc: "UTC-09:30" },
    { zone: "MAWT", name: "Mawson Station Time", utc: "UTC+05" },
    {
      zone: "MDT",
      name: "Mountain Daylight Time (North America)",
      utc: "UTC-06",
    },
    {
      zone: "MET",
      name: "Middle European Time (same zone as CET)",
      utc: "UTC+01",
    },
    {
      zone: "MEST",
      name: "Middle European Summer Time (same zone as CEST)",
      utc: "UTC+02",
    },
    { zone: "MHT", name: "Marshall Islands Time", utc: "UTC+12" },
    { zone: "MIST", name: "Macquarie Island Station Time", utc: "UTC+11" },
    { zone: "MIT", name: "Marquesas Islands Time", utc: "UTC-09:30" },
    { zone: "MMT", name: "Myanmar Standard Time", utc: "UTC+06:30" },
    { zone: "MSK", name: "Moscow Time", utc: "UTC+03" },
    { zone: "MST", name: "Malaysia Standard Time", utc: "UTC+08" },
    {
      zone: "MST",
      name: "Mountain Standard Time (North America)",
      utc: "UTC-07",
    },
    { zone: "MUT", name: "Mauritius Time", utc: "UTC+04" },
    { zone: "MVT", name: "Maldives Time", utc: "UTC+05" },
    { zone: "MYT", name: "Malaysia Time", utc: "UTC+08" },
    { zone: "NCT", name: "New Caledonia Time", utc: "UTC+11" },
    { zone: "NDT", name: "Newfoundland Daylight Time", utc: "UTC-02:30" },
    { zone: "NFT", name: "Norfolk Island Time", utc: "UTC+11" },
    { zone: "NOVT", name: "Novosibirsk Time [9]", utc: "UTC+07" },
    { zone: "NPT", name: "Nepal Time", utc: "UTC+05:45" },
    { zone: "NST", name: "Newfoundland Standard Time", utc: "UTC-03:30" },
    { zone: "NT", name: "Newfoundland Time", utc: "UTC-03:30" },
    { zone: "NUT", name: "Niue Time", utc: "UTC-11" },
    { zone: "NZDT", name: "New Zealand Daylight Time", utc: "UTC+13" },
    { zone: "NZST", name: "New Zealand Standard Time", utc: "UTC+12" },
    { zone: "OMST", name: "Omsk Time", utc: "UTC+06" },
    { zone: "ORAT", name: "Oral Time", utc: "UTC+05" },
    {
      zone: "PDT",
      name: "Pacific Daylight Time (North America)",
      utc: "UTC-07",
    },
    { zone: "PET", name: "Peru Time", utc: "UTC-05" },
    { zone: "PETT", name: "Kamchatka Time", utc: "UTC+12" },
    { zone: "PGT", name: "Papua New Guinea Time", utc: "UTC+10" },
    { zone: "PHOT", name: "Phoenix Island Time", utc: "UTC+13" },
    { zone: "PHT", name: "Philippine Time", utc: "UTC+08" },
    { zone: "PHST", name: "Philippine Standard Time", utc: "UTC+08" },
    { zone: "PKT", name: "Pakistan Standard Time", utc: "UTC+05" },
    {
      zone: "PMDT",
      name: "Saint Pierre and Miquelon Daylight Time",
      utc: "UTC-02",
    },
    {
      zone: "PMST",
      name: "Saint Pierre and Miquelon Standard Time",
      utc: "UTC-03",
    },
    { zone: "PONT", name: "Pohnpei Standard Time", utc: "UTC+11" },
    {
      zone: "PST",
      name: "Pacific Standard Time (North America)",
      utc: "UTC-08",
    },
    { zone: "PWT", name: "Palau Time[10]", utc: "UTC+09" },
    { zone: "PYST", name: "Paraguay Summer Time[11]", utc: "UTC-03" },
    { zone: "PYT", name: "Paraguay Time[12]", utc: "UTC-04" },
    { zone: "RET", name: "Réunion Time", utc: "UTC+04" },
    { zone: "ROTT", name: "Rothera Research Station Time", utc: "UTC-03" },
    { zone: "SAKT", name: "Sakhalin Island Time", utc: "UTC+11" },
    { zone: "SAMT", name: "Samara Time", utc: "UTC+04" },
    { zone: "SAST", name: "South African Standard Time", utc: "UTC+02" },
    { zone: "SBT", name: "Solomon Islands Time", utc: "UTC+11" },
    { zone: "SCT", name: "Seychelles Time", utc: "UTC+04" },
    { zone: "SDT", name: "Samoa Daylight Time", utc: "UTC-10" },
    { zone: "SGT", name: "Singapore Time", utc: "UTC+08" },
    { zone: "SLST", name: "Sri Lanka Standard Time", utc: "UTC+05:30" },
    { zone: "SRET", name: "Srednekolymsk Time", utc: "UTC+11" },
    { zone: "SRT", name: "Suriname Time", utc: "UTC-03" },
    { zone: "SST", name: "Samoa Standard Time", utc: "UTC-11" },
    { zone: "SST", name: "Singapore Standard Time", utc: "UTC+08" },
    { zone: "SYOT", name: "Showa Station Time", utc: "UTC+03" },
    { zone: "TAHT", name: "Tahiti Time", utc: "UTC-10" },
    { zone: "THA", name: "Thailand Standard Time", utc: "UTC+07" },
    {
      zone: "TFT",
      name: "French Southern and Antarctic Time[13]",
      utc: "UTC+05",
    },
    { zone: "TJT", name: "Tajikistan Time", utc: "UTC+05" },
    { zone: "TKT", name: "Tokelau Time", utc: "UTC+13" },
    { zone: "TLT", name: "Timor Leste Time", utc: "UTC+09" },
    { zone: "TMT", name: "Turkmenistan Time", utc: "UTC+05" },
    { zone: "TRT", name: "Turkey Time", utc: "UTC+03" },
    { zone: "TOT", name: "Tonga Time", utc: "UTC+13" },
    { zone: "TVT", name: "Tuvalu Time", utc: "UTC+12" },
    { zone: "ULAST", name: "Ulaanbaatar Summer Time", utc: "UTC+09" },
    { zone: "ULAT", name: "Ulaanbaatar Standard Time", utc: "UTC+08" },
    { zone: "UTC", name: "Coordinated Universal Time", utc: "UTC±00" },
    { zone: "UYST", name: "Uruguay Summer Time", utc: "UTC-02" },
    { zone: "UYT", name: "Uruguay Standard Time", utc: "UTC-03" },
    { zone: "UZT", name: "Uzbekistan Time", utc: "UTC+05" },
    { zone: "VET", name: "Venezuelan Standard Time", utc: "UTC-04" },
    { zone: "VLAT", name: "Vladivostok Time", utc: "UTC+10" },
    { zone: "VOLT", name: "Volgograd Time", utc: "UTC+03" },
    { zone: "VOST", name: "Vostok Station Time", utc: "UTC+06" },
    { zone: "VUT", name: "Vanuatu Time", utc: "UTC+11" },
    { zone: "WAKT", name: "Wake Island Time", utc: "UTC+12" },
    { zone: "WAST", name: "West Africa Summer Time", utc: "UTC+02" },
    { zone: "WAT", name: "West Africa Time", utc: "UTC+01" },
    { zone: "WEST", name: "Western European Summer Time", utc: "UTC+01" },
    { zone: "WET", name: "Western European Time", utc: "UTC±00" },
    { zone: "WIB", name: "Western Indonesian Time", utc: "UTC+07" },
    { zone: "WIT", name: "Eastern Indonesian Time", utc: "UTC+09" },
    { zone: "WITA", name: "Central Indonesia Time", utc: "UTC+08" },
    { zone: "WGST", name: "West Greenland Summer Time[14]", utc: "UTC-02" },
    { zone: "WGT", name: "West Greenland Time[15]", utc: "UTC-03" },
    { zone: "WST", name: "Western Standard Time", utc: "UTC+08" },
    { zone: "YAKT", name: "Yakutsk Time", utc: "UTC+09" },
    { zone: "YEKT", name: "Yekaterinburg Time", utc: "UTC+05" },
  ],
  // videoFormat: ['MP4', 'MXF', 'HLS', 'SRT'],
  availability_countries_and_regions: [
    {
      country: "Afghanistan",
      country_code: "AFG",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Åland Islands",
      country_code: "ALA",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Albania",
      country_code: "ALB",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Algeria",
      country_code: "DZA",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "American Samoa",
      country_code: "ASM",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "Andorra",
      country_code: "AND",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Angola",
      country_code: "AGO",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Anguilla",
      country_code: "AIA",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Antigua and Barbuda",
      country_code: "ATG",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Argentina",
      country_code: "ARG",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Armenia",
      country_code: "ARM",
      region: "Middle East",
      region_code: "meast",
    },
    {
      country: "Aruba",
      country_code: "ABW",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Australia",
      country_code: "AUS",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "Austria",
      country_code: "AUT",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Azerbaijan",
      country_code: "AZE",
      region: "Middle East",
      region_code: "meast",
    },
    {
      country: "Bahamas",
      country_code: "BHS",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Bahrain",
      country_code: "BHR",
      region: "Middle East",
      region_code: "meast",
    },
    {
      country: "Bangladesh",
      country_code: "BGD",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Barbados",
      country_code: "BRB",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Belarus",
      country_code: "BLR",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Belgium",
      country_code: "BEL",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Belize",
      country_code: "BLZ",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Benin",
      country_code: "BEN",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Bermuda",
      country_code: "BMU",
      region: "North America",
      region_code: "namerica",
    },
    {
      country: "Bhutan",
      country_code: "BTN",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Bolivia",
      country_code: "BOL",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Bonaire",
      country_code: "BES",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Bosnia-Herzegovina",
      country_code: "BIH",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Botswana",
      country_code: "BWA",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Bouvet Island",
      country_code: "BVT",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Brazil",
      country_code: "BRA",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "British Indian Ocean Territory",
      country_code: "IOT",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Brunei Darussalam",
      country_code: "BRN",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Bulgaria",
      country_code: "BGR",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Burkina Faso",
      country_code: "BFA",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Burundi",
      country_code: "BDI",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Cabo Verde",
      country_code: "CPV",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Cambodia",
      country_code: "KHM",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Cameroon",
      country_code: "CMR",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Canada",
      country_code: "CAN",
      region: "North America",
      region_code: "namerica",
    },
    {
      country: "Cayman Islands",
      country_code: "CYM",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Central African Republic",
      country_code: "CAF",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Chad",
      country_code: "TCD",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Chile",
      country_code: "CHL",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "China",
      country_code: "CHN",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Christmas Island",
      country_code: "CXR",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "Cocos (Keeling) Islands",
      country_code: "CCK",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "Colombia",
      country_code: "COL",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Comoros",
      country_code: "COM",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Congo",
      country_code: "COG",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Congo Democratic Republic of",
      country_code: "COD",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Cook Islands",
      country_code: "COK",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "Costa Rica",
      country_code: "CRI",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Cote D'Ivoire",
      country_code: "CIV",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Croatia (Hrvatska)",
      country_code: "HRV",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Cuba",
      country_code: "CUB",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Curacao",
      country_code: "CUW",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Cyprus",
      country_code: "CYP",
      region: "Middle East",
      region_code: "meast",
    },
    {
      country: "Czech Republic",
      country_code: "CZE",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Denmark",
      country_code: "DNK",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Djibouti",
      country_code: "DJI",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Dominica",
      country_code: "DMA",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Dominican Republic",
      country_code: "DOM",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Ecuador",
      country_code: "ECU",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Egypt",
      country_code: "EGY",
      region: "Middle East",
      region_code: "meast",
    },
    {
      country: "El Salvador",
      country_code: "SLV",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Equatorial Guinea",
      country_code: "GNQ",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Eritrea",
      country_code: "ERI",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Estonia",
      country_code: "EST",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Eswatini",
      country_code: "SWZ",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Ethiopia",
      country_code: "ETH",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Falkland Islands (Malvinas)",
      country_code: "FLK",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Faroe Islands",
      country_code: "FRO",
      region: "North America",
      region_code: "namerica",
    },
    {
      country: "Fiji",
      country_code: "FJI",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "Finland",
      country_code: "FIN",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "France",
      country_code: "FRA",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "French Guiana",
      country_code: "GUF",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "French Polynesia",
      country_code: "PYF",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "French Southern Territories",
      country_code: "ATF",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Gabon",
      country_code: "GAB",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Gambia",
      country_code: "GMB",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Georgia",
      country_code: "GEO",
      region: "Middle East",
      region_code: "meast",
    },
    {
      country: "Germany",
      country_code: "DEU",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Ghana",
      country_code: "GHA",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Gibraltar",
      country_code: "GIB",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Greece",
      country_code: "GRC",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Greenland",
      country_code: "GRL",
      region: "North America",
      region_code: "namerica",
    },
    {
      country: "Grenada",
      country_code: "GRD",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Guadeloupe",
      country_code: "GLP",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Guam",
      country_code: "GUM",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "Guatemala",
      country_code: "GTM",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Guernsey",
      country_code: "GGY",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Guinea",
      country_code: "GIN",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Guinea-Bissau",
      country_code: "GNB",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Guyana",
      country_code: "GUY",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Haiti",
      country_code: "HTI",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Heard Island and McDonald Islands",
      country_code: "HMD",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "Holy See",
      country_code: "VAT",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Honduras",
      country_code: "HND",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Hong Kong",
      country_code: "HKG",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Hungary",
      country_code: "HUN",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Iceland",
      country_code: "ISL",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "India",
      country_code: "IND",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Indonesia",
      country_code: "IDN",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Iran",
      country_code: "IRN",
      region: "Middle East",
      region_code: "meast",
    },
    {
      country: "Iraq",
      country_code: "IRQ",
      region: "Middle East",
      region_code: "meast",
    },
    {
      country: "Ireland",
      country_code: "IRL",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Isle of Man",
      country_code: "IMN",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Israel",
      country_code: "ISR",
      region: "Middle East",
      region_code: "meast",
    },
    {
      country: "Italy",
      country_code: "ITA",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Jamaica",
      country_code: "JAM",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Japan",
      country_code: "JPN",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Jersey",
      country_code: "JEY",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Jordan",
      country_code: "JOR",
      region: "Middle East",
      region_code: "meast",
    },
    {
      country: "Kazakhstan",
      country_code: "KAZ",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Kenya",
      country_code: "KEN",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Kiribati",
      country_code: "KIR",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "Korea Democratic People's Republic (North Korea)",
      country_code: "PRK",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Republic of Korea (South Korea)",
      country_code: "KOR",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Kuwait",
      country_code: "KWT",
      region: "Middle East",
      region_code: "meast",
    },
    {
      country: "Kyrgyzstan",
      country_code: "KGZ",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Lao People's Democratic Republic",
      country_code: "LAO",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Latvia",
      country_code: "LVA",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Lebanon",
      country_code: "LBN",
      region: "Middle East",
      region_code: "meast",
    },
    {
      country: "Lesotho",
      country_code: "LSO",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Liberia",
      country_code: "LBR",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Libyan Arab Jamahiriya",
      country_code: "LBY",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Liechtenstein",
      country_code: "LIE",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Lithuania",
      country_code: "LTU",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Luxembourg",
      country_code: "LUX",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Macao SAR of PRC (Macau)",
      country_code: "MAC",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Madagascar",
      country_code: "MDG",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Malawi",
      country_code: "MWI",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Malaysia",
      country_code: "MYS",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Maldives",
      country_code: "MDV",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Mali",
      country_code: "MLI",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Malta",
      country_code: "MLT",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Marshall Islands",
      country_code: "MHL",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "Martinique",
      country_code: "MTQ",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Mauritania",
      country_code: "MRT",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Mauritius",
      country_code: "MUS",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Mayotte",
      country_code: "MYT",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Mexico",
      country_code: "MEX",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Micronesia Federated States of",
      country_code: "FSM",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "Moldova",
      country_code: "Republic of",
      region: "MDA",
      region_code: "Europe",
    },
    {
      country: "Monaco",
      country_code: "MCO",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Mongolia",
      country_code: "MNG",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Montenegro",
      country_code: "MNE",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Montserrat",
      country_code: "MSR",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Morocco",
      country_code: "MAR",
      region: "North America",
      region_code: "namerica",
    },
    {
      country: "Mozambique",
      country_code: "MOZ",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Myanmar",
      country_code: "MMR",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Namibia",
      country_code: "NAM",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Nauru",
      country_code: "NRU",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "Nepal",
      country_code: "NPL",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Netherlands",
      country_code: "NLD",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "New Caledonia",
      country_code: "NCL",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "New Zealand",
      country_code: "NZL",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "Nicaragua",
      country_code: "NIC",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Niger",
      country_code: "NER",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Nigeria",
      country_code: "NGA",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Niue",
      country_code: "NIU",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "Norfolk Island",
      country_code: "NFK",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "North Macedonia",
      country_code: "MKD",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Northern Mariana Islands",
      country_code: "MNP",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "Norway",
      country_code: "NOR",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Oman",
      country_code: "OMN",
      region: "Middle East",
      region_code: "meast",
    },
    {
      country: "Pakistan",
      country_code: "PAK",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Palau",
      country_code: "PLW",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "Palestine",
      country_code: "PSE",
      region: "Middle East",
      region_code: "meast",
    },
    {
      country: "Panama",
      country_code: "PAN",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Papua New Guinea",
      country_code: "PNG",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "Paraguay",
      country_code: "PRY",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Peru",
      country_code: "PER",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Philippines",
      country_code: "PHL",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Pitcairn",
      country_code: "PCN",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "Poland",
      country_code: "POL",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Portugal",
      country_code: "PRT",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Puerto Rico",
      country_code: "PRI",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Qatar",
      country_code: "QAT",
      region: "Middle East",
      region_code: "meast",
    },
    {
      country: "Reunion",
      country_code: "REU",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Romania",
      country_code: "ROU",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Russian Federation",
      country_code: "RUS",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Rwanda",
      country_code: "RWA",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Saint Barthelemy",
      country_code: "BLM",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Saint Helena",
      country_code: "SHN",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Saint Kitts and Nevis",
      country_code: "KNA",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Saint Lucia",
      country_code: "LCA",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Saint Martin (French part)",
      country_code: "MAF",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Saint Pierre and Miquelon",
      country_code: "SPM",
      region: "North America",
      region_code: "namerica",
    },
    {
      country: "Saint Vincent and the Grenadines",
      country_code: "VCT",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Samoa",
      country_code: "WSM",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "San Marino",
      country_code: "SMR",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Sao Tome and Principe",
      country_code: "STP",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Saudi Arabia",
      country_code: "SAU",
      region: "Middle East",
      region_code: "meast",
    },
    {
      country: "Senegal",
      country_code: "SEN",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Serbia",
      country_code: "SRB",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Seychelles",
      country_code: "SYC",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Sierra Leone",
      country_code: "SLE",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Singapore",
      country_code: "SGP",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Sint Maarten",
      country_code: "SXM",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Slovakia",
      country_code: "SVK",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Slovenia",
      country_code: "SVN",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Solomon Islands",
      country_code: "SLB",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "Somalia",
      country_code: "SOM",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "South Africa",
      country_code: "ZAF",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "South Georgia and the South Sandwich Islands",
      country_code: "SGS",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "South Sudan",
      country_code: "SSD",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Spain",
      country_code: "ESP",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Sri Lanka",
      country_code: "LKA",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Sudan",
      country_code: "SDN",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Suriname",
      country_code: "SUR",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Svalbard and Jan Mayen",
      country_code: "SJM",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Sweden",
      country_code: "SWE",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Switzerland",
      country_code: "CHE",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "Syria",
      country_code: "SYR",
      region: "Middle East",
      region_code: "meast",
    },
    {
      country: "Taiwan",
      country_code: "TWN",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Tajikistan",
      country_code: "TJK",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Tanzania",
      country_code: "TZA",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Thailand",
      country_code: "THA",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Timor-Leste",
      country_code: "TLS",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Togo",
      country_code: "TGO",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Tokelau",
      country_code: "TKL",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "Tonga",
      country_code: "TON",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "Trinidad and Tobago",
      country_code: "TTO",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Tunisia",
      country_code: "TUN",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Turkey",
      country_code: "TUR",
      region: "Middle East",
      region_code: "meast",
    },
    {
      country: "Turkmenistan",
      country_code: "TKM",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Turks and Caicos Islands",
      country_code: "TCA",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Tuvalu",
      country_code: "TUV",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "Uganda",
      country_code: "UGA",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Ukraine",
      country_code: "UKR",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "United Arab Emirates",
      country_code: "ARE",
      region: "Middle East",
      region_code: "meast",
    },
    {
      country: "United Kingdom of Great Britain and Northern Ireland",
      country_code: "GBR",
      region: "Europe",
      region_code: "europe",
    },
    {
      country: "United States of America",
      country_code: "USA",
      region: "North America",
      region_code: "namerica",
    },
    {
      country: "United States Minor Outlying Islands",
      country_code: "UMI",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "Uruguay",
      country_code: "URY",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Uzbekistan",
      country_code: "UZB",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Vanuatu",
      country_code: "VUT",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "Venezuela (Bolivarian Republic of)",
      country_code: "VEN",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Viet Nam",
      country_code: "VNM",
      region: "APAC (Asia Pacific)",
      region_code: "apac",
    },
    {
      country: "Virgin Islands (British)",
      country_code: "VGB",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Virgin Islands (U.S.)",
      country_code: "VIR",
      region: "Latin America",
      region_code: "lamerica",
    },
    {
      country: "Wallis and Futuna",
      country_code: "WLF",
      region: "Oceania",
      region_code: "oceania",
    },
    {
      country: "Western Sahara",
      country_code: "ESH",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Yemen",
      country_code: "YEM",
      region: "Middle East",
      region_code: "meast",
    },
    {
      country: "Zambia",
      country_code: "ZMB",
      region: "Africa",
      region_code: "africa",
    },
    {
      country: "Zimbabwe",
      country_code: "ZWE",
      region: "Africa",
      region_code: "africa",
    },
  ],
  availability_countries: [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia (Plurinational State of)",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo (the Democratic Republic of the)",
    "Congo",
    "Cook Islands",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czechia",
    "Côte d'Ivoire",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands [Malvinas]",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea (the Democratic People's Republic of)",
    "Korea (the Republic of)",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia (Federated States of)",
    "Moldova (the Republic of)",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Republic of North Macedonia",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Réunion",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan (Province of China)",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom of Great Britain and Northern Ireland",
    "United States Minor Outlying Islands",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela (Bolivarian Republic of)",
    "Viet Nam",
    "Virgin Islands (British)",
    "Virgin Islands (U.S.)",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
    "Åland Islands",
  ],
};

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const platformFields = {
  tier: ["Tier 1", "Tier 2", "Tier 3"],
  devices: ["Android Devices", "Connected TVs", "iOS Devices", "Web"],
  preferred_business_model: ["Revenue Share", "Inventory Share", "Both"],
  active_users: [
    "0-50K",
    "50K-150K",
    "150-300K",
    "300-750K",
    "750-1.5Million",
    "1.5-3Mil",
    "3-5Mil",
    "5-7.5Mil",
    "7.5-10mil",
    "10Mil +",
  ],
  monthly_hov: [
    "5000+ hrs",
    "50000+ hrs",
    "500k+ hrs",
    "1mil+ hrs",
    "5mil+ hrs",
    "10mil+ hrs",
    "50mil+ hrs",
    "100mil+ hrs ",
  ],
  stream_type: [
    "HLS",
    "HEVC",
    "ZiXi",
    "SRT",
    "Zixi / SRT",
    "MediaConnect",
    "Fiber",
    "LTN",
    "RTMP",
  ],
  resolution_type: [
    "1080p",
    "720p",
    "576p",
    "540p",
    "504p",
    "480p",
    "432p",
    "404p",
    "360p",
    "288p",
    "270p",
    "240p",
    "216p",
    "Any",
  ],
  resolution: [
    "1920*1080",
    "1280*720",
    "1024*576",
    "960*540",
    "896*504",
    "854*480",
    "768*432",
    "720*404",
    "640*360",
    "512*288",
    "480*270",
    "426*240",
    "384*216",
    "Any",
  ],
  frame_rate: [
    "60",
    "50",
    "30",
    "29.97",
    "25",
    "24",
    "23.98",
    "Match to Source",
    "Any",
  ],
  audio: [
    "AAC",
    "AAX-LC 192 Kbps 48 Khz",
    "AAC (English Only)",
    "AAC-LC 96 Kbps 48 Khz",
    "AAC-LC 64Kbps 48Khz",
    "AAC-LC 128Kbps 48Khz",
    "AAC-LC 192Kbps 48Khz",
    "Any",
  ],
  gop: ["2 sec", "Any"],
  format: ["jpg", "jpeg", "png"],
  epg_format: [
    "XML TV",
    "JSON",
    "Gracenote",
    "Platform Specific XML",
    "Extended JSON",
    "Platform Specific XML & Gracenote",
    "Platform API End Point",
  ],
  subtitle: ["WebVTT", "CC-608 / CC-708", "WebVTT / CC-608", "CC-608"],
  ad_trigger: [
    "Splice Insert SCTE",
    "Splice Insert / Time Signal SCTE-35",
    "Splice Insert or Time Signal & Segmentation Descriptor",
  ],
  ad_break: ["8 mins", "10 mins", "12 mins", "8 to 10 mins", "8 to 12 mins"],
  platform_qa_cycle: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "1 to 2",
    "2 to 3",
    "3 to 4",
    "4 to 5",
    "5 to 6",
  ],
  vod_delivery_mode: ["Manual", "Automated"],
  inventory_model: [
    "Inventory Share",
    "Revenue Share",
    "Inventory Share or Revenue Share",
    "Inventory Share or Revenue Share and Ads Plus",
  ],
  analytics_share_rule: [
    "None",
    "Viewership Analytics",
    "Viewership & Content Analytics",
    "Viewership and Ad Analytics",
  ],
  ssai_by: ["Amagi", "Platform", "Amagi or Platform"],
};

export const channelDeliveryFields = {
  feedType: ["Linear", "Live", "Pop-up"],
  deliveryType: ["HLS", "Zixi / SRT", "Fiber"],
  businessModel: ["Ads+", "Content+", "Direct", "Connect"],
  dealType: ["Inventory Split", "Revenue Share"],
  tsSetup: ["TSv1", "TSv2", "TSv3"],
  deliveryStatus: [
    "Progress",
    "Delivered and waiting to go live",
    "Active",
    "Suspended",
  ],
  connectDistributionModel: ["Direct Deal", "Content Licence"],
  cdnTagging: ["Platform", "Amagi", "Content Partner"],
  ssai_fee_owner: [
    "Amagi",
    "Platform",
    "Platform & Amagi",
    "Platform & Content Partner",
    "Content Partner & Amagi",
    "Platform, Content Partner & Amagi",
  ],
};

export const vodDeliveryFields = {
  feedType: ["VOD"],
  deliveryType: ["Aspera", "S3", "HTTP", "Cloudfront"],
};

export const currencyLists = [
  {
    name: "US Dollar",
    code: "USD",
  },
  {
    name: "Canadian Dollar",
    code: "CAD",
  },
  {
    name: "Euro",
    code: "EUR",
  },
  {
    name: "United Arab Emirates Dirham",
    code: "AED",
  },
  {
    name: "Afghan Afghani",
    code: "AFN",
  },
  {
    name: "Albanian Lek",
    code: "ALL",
  },
  {
    name: "Armenian Dram",
    code: "AMD",
  },
  {
    name: "Argentine Peso",
    code: "ARS",
  },
  {
    name: "Australian Dollar",
    code: "AUD",
  },
  {
    name: "Azerbaijani Manat",
    code: "AZN",
  },
  {
    name: "Bosnia-Herzegovina Convertible Mark",
    code: "BAM",
  },
  {
    name: "Bangladeshi Taka",
    code: "BDT",
  },
  {
    name: "Bulgarian Lev",
    code: "BGN",
  },
  {
    name: "Bahraini Dinar",
    code: "BHD",
  },
  {
    name: "Burundian Franc",
    code: "BIF",
  },
  {
    name: "Brunei Dollar",
    code: "BND",
  },
  {
    name: "Bolivian Boliviano",
    code: "BOB",
  },
  {
    name: "Brazilian Real",
    code: "BRL",
  },
  {
    name: "Botswanan Pula",
    code: "BWP",
  },
  {
    name: "Belarusian Ruble",
    code: "BYN",
  },
  {
    name: "Belize Dollar",
    code: "BZD",
  },
  {
    name: "Congolese Franc",
    code: "CDF",
  },
  {
    name: "Swiss Franc",
    code: "CHF",
  },
  {
    name: "Chilean Peso",
    code: "CLP",
  },
  {
    name: "Chinese Yuan",
    code: "CNY",
  },
  {
    name: "Colombian Peso",
    code: "COP",
  },
  {
    name: "Costa Rican Colón",
    code: "CRC",
  },
  {
    name: "Cape Verdean Escudo",
    code: "CVE",
  },
  {
    name: "Czech Republic Koruna",
    code: "CZK",
  },
  {
    name: "Djiboutian Franc",
    code: "DJF",
  },
  {
    name: "Danish Krone",
    code: "DKK",
  },
  {
    name: "Dominican Peso",
    code: "DOP",
  },
  {
    name: "Algerian Dinar",
    code: "DZD",
  },
  {
    name: "Estonian Kroon",
    code: "EEK",
  },
  {
    name: "Egyptian Pound",
    code: "EGP",
  },
  {
    name: "Eritrean Nakfa",
    code: "ERN",
  },
  {
    name: "Ethiopian Birr",
    code: "ETB",
  },
  {
    name: "British Pound Sterling",
    code: "GBP",
  },
  {
    name: "Georgian Lari",
    code: "GEL",
  },
  {
    name: "Ghanaian Cedi",
    code: "GHS",
  },
  {
    name: "Guinean Franc",
    code: "GNF",
  },
  {
    name: "Guatemalan Quetzal",
    code: "GTQ",
  },
  {
    name: "Hong Kong Dollar",
    code: "HKD",
  },
  {
    name: "Honduran Lempira",
    code: "HNL",
  },
  {
    name: "Croatian Kuna",
    code: "HRK",
  },
  {
    name: "Hungarian Forint",
    code: "HUF",
  },
  {
    name: "Indonesian Rupiah",
    code: "IDR",
  },
  {
    name: "Israeli New Sheqel",
    code: "ILS",
  },
  {
    name: "Indian Rupee",
    code: "INR",
  },
  {
    name: "Iraqi Dinar",
    code: "IQD",
  },
  {
    name: "Iranian Rial",
    code: "IRR",
  },
  {
    name: "Icelandic Króna",
    code: "ISK",
  },
  {
    name: "Jamaican Dollar",
    code: "JMD",
  },
  {
    name: "Jordanian Dinar",
    code: "JOD",
  },
  {
    name: "Japanese Yen",
    code: "JPY",
  },
  {
    name: "Kenyan Shilling",
    code: "KES",
  },
  {
    name: "Cambodian Riel",
    code: "KHR",
  },
  {
    name: "Comorian Franc",
    code: "KMF",
  },
  {
    name: "South Korean Won",
    code: "KRW",
  },
  {
    name: "Kuwaiti Dinar",
    code: "KWD",
  },
  {
    name: "Kazakhstani Tenge",
    code: "KZT",
  },
  {
    name: "Lebanese Pound",
    code: "LBP",
  },
  {
    name: "Sri Lankan Rupee",
    code: "LKR",
  },
  {
    name: "Lithuanian Litas",
    code: "LTL",
  },
  {
    name: "Latvian Lats",
    code: "LVL",
  },
  {
    name: "Libyan Dinar",
    code: "LYD",
  },
  {
    name: "Moroccan Dirham",
    code: "MAD",
  },
  {
    name: "Moldovan Leu",
    code: "MDL",
  },
  {
    name: "Malagasy Ariary",
    code: "MGA",
  },
  {
    name: "Macedonian Denar",
    code: "MKD",
  },
  {
    name: "Myanma Kyat",
    code: "MMK",
  },
  {
    name: "Macanese Pataca",
    code: "MOP",
  },
  {
    name: "Mauritian Rupee",
    code: "MUR",
  },
  {
    name: "Mexican Peso",
    code: "MXN",
  },
  {
    name: "Malaysian Ringgit",
    code: "MYR",
  },
  {
    name: "Mozambican Metical",
    code: "MZN",
  },
  {
    name: "Namibian Dollar",
    code: "NAD",
  },
  {
    name: "Nigerian Naira",
    code: "NGN",
  },
  {
    name: "Nicaraguan Córdoba",
    code: "NIO",
  },
  {
    name: "Norwegian Krone",
    code: "NOK",
  },
  {
    name: "Nepalese Rupee",
    code: "NPR",
  },
  {
    name: "New Zealand Dollar",
    code: "NZD",
  },
  {
    name: "Omani Rial",
    code: "OMR",
  },
  {
    name: "Panamanian Balboa",
    code: "PAB",
  },
  {
    name: "Peruvian Nuevo Sol",
    code: "PEN",
  },
  {
    name: "Philippine Peso",
    code: "PHP",
  },
  {
    name: "Pakistani Rupee",
    code: "PKR",
  },
  {
    name: "Polish Zloty",
    code: "PLN",
  },
  {
    name: "Paraguayan Guarani",
    code: "PYG",
  },
  {
    name: "Qatari Rial",
    code: "QAR",
  },
  {
    name: "Romanian Leu",
    code: "RON",
  },
  {
    name: "Serbian Dinar",
    code: "RSD",
  },
  {
    name: "Russian Ruble",
    code: "RUB",
  },
  {
    name: "Rwandan Franc",
    code: "RWF",
  },
  {
    name: "Saudi Riyal",
    code: "SAR",
  },
  {
    name: "Sudanese Pound",
    code: "SDG",
  },
  {
    name: "Swedish Krona",
    code: "SEK",
  },
  {
    name: "Singapore Dollar",
    code: "SGD",
  },
  {
    name: "Somali Shilling",
    code: "SOS",
  },
  {
    name: "Syrian Pound",
    code: "SYP",
  },
  {
    name: "Thai Baht",
    code: "THB",
  },
  {
    name: "Tunisian Dinar",
    code: "TND",
  },
  {
    name: "Tongan Paʻanga",
    code: "TOP",
  },
  {
    name: "Turkish Lira",
    code: "TRY",
  },
  {
    name: "Trinidad and Tobago Dollar",
    code: "TTD",
  },
  {
    name: "New Taiwan Dollar",
    code: "TWD",
  },
  {
    name: "Tanzanian Shilling",
    code: "TZS",
  },
  {
    name: "Ukrainian Hryvnia",
    code: "UAH",
  },
  {
    name: "Ugandan Shilling",
    code: "UGX",
  },
  {
    name: "Uruguayan Peso",
    code: "UYU",
  },
  {
    name: "Uzbekistan Som",
    code: "UZS",
  },
  {
    name: "Venezuelan Bolívar",
    code: "VEF",
  },
  {
    name: "Vietnamese Dong",
    code: "VND",
  },
  {
    name: "CFA Franc BEAC",
    code: "XAF",
  },
  {
    name: "CFA Franc BCEAO",
    code: "XOF",
  },
  {
    name: "Yemeni Rial",
    code: "YER",
  },
  {
    name: "South African Rand",
    code: "ZAR",
  },
  {
    name: "Zambian Kwacha",
    code: "ZMK",
  },
  {
    name: "Zimbabwean Dollar",
    code: "ZWL",
  },
];

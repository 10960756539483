import { useMemo, useState } from "react";
import { useCDM } from "../../cdm-context";
import Loader from "../../common/loader";
import TableContainer from "../../common/table-container";
import { useFinancialStatementsQuery } from "../../hasura.graphql";
import ExportCSV from "../exportComponents/exportCSV";
import { MonthParser } from "../utils";
import { DateRangeType, StatementofAccountsType } from "./types";
import { capitalize } from "lodash";
import CustomDatePicker from "../custom-date-picker";
import { DateTime } from "luxon";
import getSymbolFromCurrency from "currency-symbol-map";
import { TransactionType } from ".";
import { useNavigate } from "react-router-dom";

const FinancialStatement: React.FC<{
  setTransaction: React.Dispatch<React.SetStateAction<TransactionType>>;
  setTab: (tab: string) => void;
  isModal: boolean;
}> = ({ setTransaction, setTab, isModal }) => {
  const { show } = useCDM();
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState<DateRangeType>({
    from_date: DateTime.local().minus({ year: 1 }).toISO().slice(0, 10),
    to_date: DateTime.local().toISO().slice(0, 10),
  });

  const handleDateRange = (fromDate: string, toDate: string) => {
    setDateRange({
      from_date: fromDate,
      to_date: toDate,
    });
  };

  const matchUrls = useMemo(
    () => [
      {
        type: "Invoice",
        url: "invoices",
        tab: "Invoices",
      },
      {
        type: "Creditmemo",
        url: "credit-memos",
        tab: "Credit Memos",
      },
      {
        type: "Receipt",
        url: "receipts",
        tab: "Receipts",
      },
    ],
    []
  );

  const columns: any = useMemo(
    () => [
      {
        Header: "TRANSACTION DATE",
        accessor: "transaction_date",
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.transaction_date !== "--"
              ? MonthParser(cell.row.values.transaction_date)
              : "--"}
          </span>
        ),
      },
      {
        Header: "TRANSACTION TYPE",
        accessor: "transaction_type",
      },
      {
        Header: "TRANSACTION ID",
        accessor: "transaction_id",
        Cell: ({ cell }: any) => (
          <span
            className="cursor-pointer hover:text-blue-500 hover:underline"
            onClick={() => {
              setTab(
                matchUrls.find(
                  (match) => match.type === cell.row.values.transaction_type
                )?.tab!
              );
              !isModal &&
                navigate(
                  `/customers/${show?.amg_id}/${
                    matchUrls.find(
                      (match) => match.type === cell.row.values.transaction_type
                    )?.url
                  }?search=${cell.row.values.transaction_id}&date=${
                    cell.row.values.transaction_date
                  }-01`
                );
              setTransaction({
                id: cell.row.values.transaction_id,
                date: `${cell.row.values.transaction_date}-01`,
              });
            }}
          >
            {cell.row.values.transaction_id}
          </span>
        ),
      },
      {
        Header: "INVOICED AMT",
        accessor: "charges",
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.charges
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: show?.currency || "USD",
                }).format(cell.row.values.charges)
              : "--"}
          </span>
        ),
      },
      {
        Header: "RECEIVED AMT",
        accessor: "payment",
        Cell: ({ cell }: any) => (
          <>
            {cell.row.values.payment ? (
              <span className="text-green-500">
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: show?.currency || "USD",
                }).format(cell.row.values.payment)}
              </span>
            ) : (
              <span>--</span>
            )}
          </>
        ),
      },
      {
        Header: "CREDITED AMT",
        accessor: "credits",
        Cell: ({ cell }: any) => (
          <>
            {cell.row.values.credits ? (
              <span className="text-red-500">
                {`${getSymbolFromCurrency(
                  show?.currency || "USD"
                )}(${cell.row.values.credits.toFixed(2)})`}
              </span>
            ) : (
              <span>--</span>
            )}
          </>
        ),
      },
      {
        Header: "BALANCE",
        accessor: "balance",
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.balance
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: show?.currency || "USD",
                }).format(cell.row.values.balance)
              : "--"}
          </span>
        ),
      },
    ],
    [
      isModal,
      matchUrls,
      navigate,
      setTab,
      setTransaction,
      show?.amg_id,
      show?.currency,
    ]
  );

  const { data, loading, error } = useFinancialStatementsQuery({
    variables: {
      amagi_id: show?.amg_id!,
      from_date: dateRange.from_date,
      to_date: dateRange.to_date,
    },
  });

  if (loading)
    return (
      <>
        <div className="py-1 mt-4 bg-white px-6 border-b font-medium flex justify-between items-center">
          <div className="my-1 w-1/3">Statement of Accounts</div>
          <div className="flex justify-end items-center">
            <CustomDatePicker
              handleDateRange={handleDateRange}
              fromDate={dateRange.from_date}
              toDate={dateRange.to_date}
            />
          </div>
        </div>
        <div
          className="bg-white pt-4 pl-4"
          style={{ height: `${window.innerHeight - 245}px` }}
        >
          <Loader />
        </div>
      </>
    );
  if (error)
    return (
      <>
        <div className="py-1 mt-4 bg-white px-6 border-b font-medium flex justify-between items-center">
          <div className="my-1 w-1/3">Statement of Accounts</div>
          <div className="flex justify-end items-center">
            <CustomDatePicker
              handleDateRange={handleDateRange}
              fromDate={dateRange.from_date}
              toDate={dateRange.to_date}
            />
          </div>
        </div>
        <div className="bg-white py-3 pl-6">
          <i>Error</i>
        </div>
      </>
    );
  if (!data) return <i>No Data</i>;

  const financialStatementData: Array<StatementofAccountsType> =
    data?.account_statement?.statements &&
    data?.account_statement?.statements.length > 0
      ? data?.account_statement?.statements.map((data) => {
          return {
            transaction_date: data.transaction_date
              ? data.transaction_date
              : "--",
            transaction_type: data.transaction_type
              ? capitalize(data.transaction_type)
              : "--",
            transaction_id: data.transaction_id ? data.transaction_id : "--",
            charges: data.charges ? data.charges : null,
            payment:
              data.payment && data.transaction_type !== "creditmemo"
                ? data.payment
                : null,
            credits:
              data.payment && data.transaction_type === "creditmemo"
                ? data.payment
                : null,
            balance: data.balance ? data.balance : null,
          };
        })
      : [];

  const totalCreditAmount =
    data.account_statement?.statements &&
    data?.account_statement?.statements.length > 0
      ? data.account_statement?.statements
          .filter((data) => data.transaction_type === "creditmemo")
          .reduce((a, b) => a + b.payment, 0)
      : 0;

  const totalReceiptAmount = Math.abs(
    (data?.account_statement?.grand_total &&
    data?.account_statement?.grand_total.length > 0
      ? data?.account_statement?.grand_total[0].payment
      : 0) - totalCreditAmount
  );

  const exportHeaders = columns.map((data: any) => {
    return {
      label: data.Header,
      key: data.accessor,
    };
  });

  const exportData = [
    {
      transaction_id: "Outstanding",
      transaction_date: "",
      transaction_type: "",
      charges: "",
      payment: "",
      balance: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: show?.currency || "USD",
      }).format(
        data?.account_statement?.outstanding &&
          data?.account_statement?.outstanding.length > 0
          ? data?.account_statement?.outstanding[0].balance
          : 0
      ),
    },
    ...financialStatementData.map((fin) => {
      return {
        transaction_id: fin.transaction_id,
        transaction_date: fin.transaction_date,
        transaction_type: fin.transaction_type,
        charges: fin.charges
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: show?.currency || "USD",
            }).format(fin.charges)
          : "--",
        payment: fin.payment
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: show?.currency || "USD",
            }).format(fin.payment)
          : "--",
        credits: fin.credits
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: show?.currency || "USD",
            }).format(fin.credits)
          : "--",
        balance: fin.balance
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: show?.currency || "USD",
            }).format(fin.balance)
          : "--",
      };
    }),
    {
      transaction_id: "Grand Total",
      transaction_date: "",
      transaction_type: "",
      charges: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: show?.currency || "USD",
      }).format(
        data?.account_statement?.grand_total &&
          data?.account_statement?.grand_total.length > 0
          ? data?.account_statement?.grand_total[0].charges
          : 0
      ),
      payment: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: show?.currency || "USD",
      }).format(
        data?.account_statement?.grand_total &&
          data?.account_statement?.grand_total.length > 0
          ? data?.account_statement?.grand_total[0].payment
          : 0
      ),
      balance: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: show?.currency || "USD",
      }).format(
        data?.account_statement?.grand_total &&
          data?.account_statement?.grand_total.length > 0
          ? data?.account_statement?.grand_total[0].balance
          : 0
      ),
      credits: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: show?.currency || "USD",
      }).format(
        totalCreditAmount
      ),
    },
  ];

  return (
    <div className="mt-4 bg-white">
      <div className="py-1 px-6 border-b font-medium flex justify-between items-center">
        <div className="my-1 w-1/3">Statement of Account</div>
        <div className="flex justify-end items-center">
          <CustomDatePicker
            handleDateRange={handleDateRange}
            fromDate={dateRange.from_date}
            toDate={dateRange.to_date}
          />
          <div className="ml-3">
            <ExportCSV
              csvData={exportData || []}
              csvHeaders={exportHeaders}
              fileName={`${show?.amg_id}_Account_Statement.csv`}
            />
          </div>
        </div>
      </div>
      {financialStatementData.length > 0 ? (
        <div>
          <TableContainer
            outstanding={
              data?.account_statement?.outstanding &&
              data?.account_statement?.outstanding.length > 0
                ? data?.account_statement?.outstanding[0].balance
                : 0
            }
            charges={
              data?.account_statement?.grand_total &&
              data?.account_statement?.grand_total.length > 0
                ? data?.account_statement?.grand_total[0].charges
                : 0
            }
            payment={totalReceiptAmount}
            credits={totalCreditAmount}
            balance={
              data?.account_statement?.grand_total &&
              data?.account_statement?.grand_total.length > 0
                ? data?.account_statement?.grand_total[0].balance
                : 0
            }
            data={financialStatementData}
            columns={columns}
          />
        </div>
      ) : (
        <div className="p-4 bg-white italic font-medium w-full">
          No Statement of Account records found
        </div>
      )}
    </div>
  );
};

export default FinancialStatement;

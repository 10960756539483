import { useCDM } from "../../cdm-context";
import {
  useCustomerByPkQuery,
  useFeatureFlagQuery,
} from "../../hasura.graphql";
import Customer from "../../components/customer";
import Modal from "../../components/modal";
import Loader from "../../common/loader";
import { useCDPRole } from "../../cdp-role-context";

const CustomerModal: React.FC = () => {
  const { show, setShow } = useCDM();
  const { setCDPRole } = useCDPRole();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const role_data = useFeatureFlagQuery({
    onCompleted: (data) => {
      setCDPRole(data.feature_flag.map((feat) => feat.feature));
    },
  });
  

  if (!show) {
    return <></>;
  }

  return (
    <Modal
      title="All Customers"
      modalShow={show}
      onClose={setShow}
      isCustomer={true}
      modalWidth={"100vw"}
    >
      <RenderCustomerModal />
    </Modal>
  );
};

const RenderCustomerModal: React.FC = () => {
  const { show } = useCDM();
  const { data, loading, error } = useCustomerByPkQuery({
    variables: {
      amagi_id: show!.amg_id,
    },
    skip: !show?.amg_id,
  });

  if (loading) {
    return (
      <div className="m-6">
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div>There was an error: {JSON.stringify(error, null, 2)}</div>;
  }

  if (!data || !data.customer) {
    return <div>Customer not found</div>;
  }

  return <Customer isModal={true} customer={data.customer} />;
};

export default CustomerModal;

import React, { useState,useEffect, useRef  } from "react";
import {
  useGetTopPayingCustomersQuery,
  useGetTopCustomersConsumptionQuery,
  useGetTopCustomersSubscriptionQuery,
} from "../../hasura.graphql";
import Loader from "../../common/loader";
import { DateTime } from "luxon";

const DQCustomerMetrics: React.FC = () => {
  const [monthRange, setMonthRange] = useState<string>("12");
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const initialDisplayCounts: { [key: string]: number } = {
    Paying: 5,
    Consumption: 5,
    Subscription: 5,
  };
  const [displayCounts, setDisplayCounts] = useState<{ [key: string]: number }>(
    initialDisplayCounts
  );
  const [, setViewAllCustomers] = useState<boolean>(false);
  const [showAllCustomers] = useState<boolean>(false);
  const [expandedCells, setExpandedCells] = useState<string | null>(null);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // Track the individual view more states for each column
  const [viewMoreStates, setViewMoreStates] = useState<{
    [key: string]: boolean;
  }>({
    Paying: false,
    Consumption: false,
    Subscription: false,
  });

  // GraphQL Queries
  const payingCustomersQuery = useGetTopPayingCustomersQuery();
  const consumptionQuery = useGetTopCustomersConsumptionQuery({
    variables: { from_date: getFromDate(monthRange), to_date: getToDate() },
  });
  const subscriptionQuery = useGetTopCustomersSubscriptionQuery({
    variables: { from_date: getFromDate(monthRange), to_date: getToDate() },
  });

  const payingCustomersData =
    payingCustomersQuery.data?.cdp_top10_paying_cust_mv;
  const consumptionData = consumptionQuery.data?.top_customers_consumption;
  const subscriptionData = subscriptionQuery.data?.top_customers_subscription;

  const payingCustomersLoading = payingCustomersQuery.loading;
  const consumptionLoading = consumptionQuery.loading;
  const subscriptionLoading = subscriptionQuery.loading;

  const payingCustomersError = payingCustomersQuery.error;
  const consumptionError = consumptionQuery.error;
  const subscriptionError = subscriptionQuery.error;

  if (payingCustomersLoading || consumptionLoading || subscriptionLoading)
    return <Loader />;
  if (payingCustomersError || consumptionError || subscriptionError)
    return <i>Error</i>;

  const handleViewToggleClick = (columnName: string) => {
    setViewMoreStates((prevStates) => ({
      ...prevStates,
      [columnName]: !prevStates[columnName],
    }));

    // Reset the display count if "View Less" is clicked
    if (viewMoreStates[columnName]) {
      setDisplayCounts((prevDisplayCounts) => ({
        ...prevDisplayCounts,
        [columnName]: initialDisplayCounts[columnName],
      }));
    } else {
      // Set the display count to show all customers for the clicked column
      setDisplayCounts((prevDisplayCounts) => ({
        ...prevDisplayCounts,
        [columnName]: getCustomerDataLength(columnName) || 0,
      }));
    }
  };

  const handleViewAllCustomersClick = () => {
    setViewAllCustomers(true);

    // Set all viewMoreStates to true
    setViewMoreStates({
      Paying: true,
      Consumption: true,
      Subscription: true,
    });

    // Set display count to show all data
    setDisplayCounts({
      Paying: getCustomerDataLength("Paying") || 0,
      Consumption: getCustomerDataLength("Consumption") || 0,
      Subscription: getCustomerDataLength("Subscription") || 0,
    });
  };

  return (
    <div className="bg-transparent">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-4">
          <div className="text-xl font-semibold">Customer Metrics</div>
          <button
            type="button"
            onClick={handleViewAllCustomersClick}
            className="text-blue-500 hover:underline focus:outline-none font-bold cursor-pointer"
          >
            View all customers
          </button>
        </div>

        <div className="relative">
          <div
            onClick={() => setDropdownOpen(!isDropdownOpen)}
            className="cursor-pointer flex items-center bg-gray-200 rounded-md border border-black p-1 gap-2"
            style={{
              width: "149px",
              height: "32px",
              borderRadius: "4px",
              border: "1px solid #CDD2D5",
              background: "#EDF2F6",
            }}
          >
            <div className="ml-1">{`Last ${monthRange} months`}</div>
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="text-gray-500"
            >
              <path
                d="M21 3H20V1H18V3H6V1H4V3H3C1.89543 3 1 3.89543 1 5V19C1 20.1046 1.89543 21 3 21H21C22.1046 21 23 20.1046 23 19V5C23 3.89543 22.1046 3 21 3ZM21 19H3V8H21V19ZM21 6H3V5H21V6Z"
                fill="currentColor"
              />
            </svg>
          </div>
          <div className="relative" ref={dropdownRef}>
          {isDropdownOpen && (
            <div className="absolute top-full left-0 mt-1 bg-white border rounded-md overflow-hidden">
              <div
                onClick={() => {
                  setDropdownOpen(false);
                  setMonthRange("3");
                }}
                className="text-sm text-gray-800 cursor-pointer p-2 hover:bg-gray-100"
              >
                Last 3 months
              </div>
              <div
                onClick={() => {
                  setDropdownOpen(false);
                  setMonthRange("6");
                }}
                className="text-sm text-gray-800 cursor-pointer p-2 hover:bg-gray-100"
              >
                Last 6 months
              </div>
              <div
                onClick={() => {
                  setDropdownOpen(false);
                  setMonthRange("12");
                }}
                className="text-sm text-gray-800 cursor-pointer p-2 hover:bg-gray-100"
              >
                Last 12 months
              </div>
            </div>
          )}
        </div>
      </div>
      </div>

      <div className="grid grid-cols-3 gap-4">
        {/* Column 1: Top Paying Customers */}
        {renderCustomerColumn(
          payingCustomersData,
          "Paying",
          "Top paying customers"
        )}

        {/* Column 2: Subscription */}
        {renderCustomerColumn(
          subscriptionData,
          "Subscription",
          "Top customer by subscription revenue"
        )}

        {/* Column 3: Consumption */}
        {renderCustomerColumn(
          consumptionData,
          "Consumption",
          "Top customer by consumption revenue"
        )}
      </div>

      {/* Add bottom margin */}
      <div className="mt-8" />
    </div>
  );

  function renderCustomerColumn(
    columnData: any,
    columnName: string,
    headingText: string
  ) {
    const displayCount = showAllCustomers
      ? getCustomerDataLength(columnName)
      : displayCounts[columnName];
    const viewToggleText =
      showAllCustomers || displayCount === 10 ? "View less" : "View all";

    return (
      <div className="mr-4">
        <div className="font-bold mb-4 flex items-center relative">
          {headingText}
          <div className="icon-container">
            <div className="info-icon" title={headingText}>
              <svg
                width="13.33"
                height="13.33"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="text-black"
                style={{
                  width: "25.33px",
                  height: "13.33px",
                  top: "1.33px",
                  left: "4.33px",
                }}
              >
                <circle
                  cx="12"
                  cy="12"
                  r="10"
                  fill="rgba(0,0,0,0)"
                  stroke="black"
                  strokeWidth="2"
                />
                <text
                  x="50%"
                  y="50%"
                  textAnchor="middle"
                  dy=".3em"
                  fontSize="14"
                  fill="black"
                >
                  i
                </text>
              </svg>
            </div>
          </div>
        </div>
        {columnData
          ?.slice(0, displayCount)
          ?.map((customer: any, index: number) => (
            <div
              key={customer?.account_name}
              className="bg-white p-4 mb-2 border"
              style={{
                borderRadius: "8px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "8px 16px",
              }}
            >
              <div
                className="flex items-center mb-2"
                style={{ margin: 0, padding: 0 }}
              >
                <div
                  className="logo-box rounded-md border bg-lightPrimary"
                  style={{
                    width: "80px",
                    height: "62px",
                    overflow: "hidden",
                    marginLeft: "-16px",
                    marginTop: "-8px",
                    marginBottom: "-8px",
                    borderRadius: "5px 0 0 5px",
                  }}
                >
                  {!customer.logo && (
                    <div
                      className="max-w-full max-h-full mr-2 rounded-lg w-full h-full flex font-semibold text-xl text-gray-300 items-center justify-center object-contain border-b bg-gradient-to-r from-gradientBlue to-gradientOrange"
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "0px 0px 1px 0px",
                        margin: 0,
                        padding: 0,
                        borderRadius: "5px 0 0 5px",
                      }}
                    >
                      {customer.account_name
                        ? customer.account_name[0]
                        : "null"}
                    </div>
                  )}
                  {customer.logo && (
                    <img
                      src={`logos/${customer.logo}`}
                      alt={customer.account_name || "null"}
                      className="max-w-full max-h-full mr-2"
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "0px 0px 1px 0px",
                        margin: 0,
                        padding: 0,
                        borderRadius: "5px 0 0 5px",
                      }}
                      onError={(e) => {
                        e.currentTarget.src = `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${
                          customer.account_name
                            ? customer.account_name[0]
                            : "null"
                        }`;
                      }}
                    />
                  )}
                </div>

                <div className="flex-grow">
                  <span className="text-black ml-2">{index + 1}</span>
                  <a
                  href={`/customers/${customer.amagi_id}/details`}
                  className="text-blue-500 ml-2 font-bold"
                  onMouseEnter={() => setExpandedCells(customer.account_name)}
                  onMouseLeave={() => setExpandedCells(null)}
                  style={{ cursor: "pointer" }}
                  >
                    {expandedCells === customer.account_name ||
                    customer.account_name === null
                      ? customer?.account_name || "null"
                      : truncateAccountName(customer?.account_name)}
                  </a>
                    <div className="text-gray-500 font-bold ml-2">
                      {(
                        (columnName === "Paying"
                          ? customer?.invoice_amount_usd
                          : columnName === "Consumption"
                          ? customer?.total
                          : columnName === "Subscription"
                          ? customer?.total
                          : 0) / 1_000_000 || 0
                      ).toFixed(2)}
                      M USD
                    </div>
                </div>
              </div>
            </div>
          ))}
        {columnData?.length > 5 && (
          <button
            type="button"
            onClick={() => handleViewToggleClick(columnName)}
            className="text-blue-500 hover:underline focus:outline-none font-bold cursor-pointer"
            style={{ marginTop: "8px" }}
          >
            {viewToggleText}
          </button>
        )}
      </div>
    );
  }

  function truncateAccountName(accountName: string | null | undefined) {
    if (!accountName) return "";
    const maxLength = 30; // Set the max length
    return accountName.length > maxLength
      ? accountName.substring(0, maxLength) + "..."
      : accountName;
  }

  function getFromDate(monthRange: string) {
    return DateTime.local()
      .startOf("month")
      .minus({ months: parseInt(monthRange) })
      .toISO()
      .slice(0, 10);
  }

  function getToDate() {
    return DateTime.local()
      .minus({ months: 1 })
      .endOf("month")
      .toISO()
      .slice(0, 10);
  }

  function getCustomerDataLength(columnName: string) {
    switch (columnName) {
      case "Paying":
        return payingCustomersData?.length || 0;
      case "Consumption":
        return consumptionData?.length || 0;
      case "Subscription":
        return subscriptionData?.length || 0;
      default:
        return 0;
    }
  }
};

export default DQCustomerMetrics;
import { DeliveryRegionsType } from "../delivery-edit-form/types";

const ViewDeliveryRegions: React.FC<{
  delRegions: Array<DeliveryRegionsType>;
}> = ({ delRegions }) => {
  return (
    <div>
      <label className="text-gray-400">Delivery Regions</label>
      {delRegions.length > 0 ? (
        <table
          className="mt-4 border"
          style={{ width: "60%", fontSize: "16px" }}
        >
          <thead className="border-0 border-b border-gray-50">
            <tr className="bg-gray-300">
              <th className="py-2 pl-2">S No.</th>
              <th className="text-left py-2 pl-2">Region</th>
              <th className="text-left py-2 pl-2">Countries</th>
              <th className="text-left py-2 pl-2">Countries Exceptions</th>
            </tr>
          </thead>
          <tbody className="bg-gray-50">
            {delRegions.map((region: DeliveryRegionsType, index: number) => (
              <tr
                key={index}
                className="border-0 border-b border-gray-200 last:border-0"
              >
                <td className="py-1 pl-2">{index + 1}</td>
                <td className="text-left py-2 pl-2">{region.region}</td>
                <td className="text-left py-2 pl-2">
                  {/* <div className="grid grid-cols-2 gap-2"> */}
                  {region.countries.map((country, index) => (
                    <span
                      key={index}
                      className="mb-1 px-3 flex cursor-pointer rounded-sm text-black font-semibold mr-2 border border-gray-300 w-max"
                    >
                      {country}
                    </span>
                  ))}
                  {/* </div> */}
                </td>
                <td className="text-left py-2 pl-2">
                  {/* <div className="grid grid-cols-2 gap-2"> */}
                  {region.countries_exception.map((country, index) => (
                    <span
                      key={index}
                      className="mb-1 px-3 flex cursor-pointer rounded-sm text-black font-semibold border border-gray-300 w-max"
                    >
                      {country}
                    </span>
                  ))}
                  {/* </div> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="mt-2">-</div>
      )}
    </div>
  );
};

export default ViewDeliveryRegions;
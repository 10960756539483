import React, { useState,useEffect, useRef  } from "react";
import { useGetAverageAmountPerCustomerQuery } from "../../hasura.graphql";
import { DateTime } from "luxon";

const DQCustomerAverageMetrics: React.FC = () => {
  const [dateRange, setDateRange] = useState<string>("12");
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const { loading, error, data } = useGetAverageAmountPerCustomerQuery({
    variables: {
      from_date: getFromDate(dateRange),
      to_date: getToDate(),
    },
  });

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-4">
          <div className="text-xl font-semibold">Average revenue per customer</div>
        </div>
        <div className="relative">
          <div
            onClick={() => setDropdownOpen(!isDropdownOpen)}
            className="cursor-pointer flex items-center bg-gray-200 rounded-md border border-black p-1 gap-2"
            style={{
              width: "149px",
              height: "32px",
              borderRadius: "4px",
              border: "1px solid #CDD2D5",
              background: "#EDF2F6",
            }}
          >
            <div className="ml-1">{`Last ${dateRange} months`}</div>
            {/* Add your custom calendar icon here */}
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="text-gray-500"
            >
              <path
                d="M21 3H20V1H18V3H6V1H4V3H3C1.89543 3 1 3.89543 1 5V19C1 20.1046 1.89543 21 3 21H21C22.1046 21 23 20.1046 23 19V5C23 3.89543 22.1046 3 21 3ZM21 19H3V8H21V19ZM21 6H3V5H21V6Z"
                fill="currentColor"
              />
            </svg>
          </div>
          <div className="relative" ref={dropdownRef}>
          {isDropdownOpen && (
            <div
              className="absolute top-full left-0 mt-1 bg-white border rounded-md overflow-hidden z-10"
              style={{
                width: "149px",
                borderRadius: "4px",
                border: "1px solid #e0e0e0",
                padding: "6px 12px",
                gap: "2px",
              }}
            >
              <div
                onClick={() => {
                  setDropdownOpen(false);
                  setDateRange("3");
                }}
                className="text-sm text-gray-800 cursor-pointer p-2 hover:bg-gray-100"
              >
                Last 3 months
              </div>
              <div
                onClick={() => {
                  setDropdownOpen(false);
                  setDateRange("6");
                }}
                className="text-sm text-gray-800 cursor-pointer p-2 hover:bg-gray-100"
              >
                Last 6 months
              </div>
              <div
                onClick={() => {
                  setDropdownOpen(false);
                  setDateRange("12");
                }}
                className="text-sm text-gray-800 cursor-pointer p-2 hover:bg-gray-100"
              >
                Last 12 months
              </div>
            </div>
          )}
        </div>
      </div>
      </div>

      <div className="grid grid-cols-3 gap-2">
        {/* Card 1: Average Revenue */}
        {renderCard(
          "Average Revenue",
          data?.average_customer_revenue[0]?.value,
          undefined,
          "Average customer revenue"
        )}

        {/* Card 2: Average Subscription Revenue */}
        {renderCard(
          "Average Subscription Revenue",
          data?.average_subscription_revenue[0]?.value,
          undefined,
          "Average customer revenue subscription"
        )}

        {/* Card 3: Average Consumption Revenue */}
        {renderCard(
          "Average Consumption Revenue",
          data?.average_consumption_revenue[0]?.value,
          undefined,
          "Average customer revenue consumption"
        )}
      </div>
    </div>
  );

  function renderCard(title: string, amount: number | undefined, count: number | undefined, iconName: string) {
    const formattedAmount = formatRevenue(amount, count);
    return (
      <div className="bg-white p-4 rounded-md border border-solid border-gray-300 text-center">
        <div className="font-semibold flex items-center justify-center relative" style={{ marginBottom: "8px" }}>
          {title}
          <div className="icon-container">
            <div className="info-icon" title={iconName}>
              {/* Information icon SVG */}
              <svg
                width="13.33"
                height="13.33"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="text-gray"
                style={{ width: "25.33px", height: "13.33px", top: "1.33px", left: "4.33px" }}
              >
                <circle cx="12" cy="12" r="10" fill="rgba(0,0,0,0)" stroke="black" strokeWidth="2" />
                <text x="50%" y="50%" textAnchor="middle" dy=".3em" fontSize="14" fill="black">
                  i
                </text>
              </svg>
            </div>
          </div>
        </div>
        <div className="text-2xl font-bold text-gray-800">{formattedAmount}</div>
      </div>
    );
  }
  
    function formatRevenue(amount: number | undefined, count: number | undefined) {
    if (amount !== undefined) {
      const avgRevenue = (amount / (count || 1)) / 1_000_000;
      const formattedAmount = `${avgRevenue.toFixed(2)}M USD`;

      // Split the formatted amount into parts
      const [amountValue, currency] = formattedAmount.split(" ");

      return (
        <span>
          <span style={{ fontSize: "1.2em", color: "#0F0F0F" }}>{amountValue}</span>
          <span style={{ fontSize: "0.8em", color: "grey", marginLeft: "4px" }}>{currency}</span>
        </span>
      );
    }
    return "0M USD";
  }

  function getFromDate(monthRange: string) {
    return DateTime.local()
      .startOf("month")
      .minus({ months: parseInt(monthRange) })
      .toISO()
      .slice(0, 10);
  }

  function getToDate() {
    return DateTime.local().minus({ months: 1 }).endOf("month").toISO().slice(0, 10);
  }
};

export default DQCustomerAverageMetrics;